import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ColLeftLogin, OthersLogin } from "./LoginAssos";
import { HiOutlineUpload } from "react-icons/hi";
const JustificatifAssos = () => {

  return (
    <div className="auth-component">
      <div className="fixed-after-header fixed-login-page-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-6 auth-col auth-left-side-col">
                <ColLeftLogin />
              </div>
              <div className="col-md-6 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="p-5">
                    <div className="auth-form-container">
                    <h1 className="auth-form-title">Justificatifs</h1>
                      <div className="content-text-redirect-auth mt-4">
                        <span className="info-connect-redirection">
                        Maintenant renseignez le nom et la pièce justificative de l’association
                        </span>
                      </div>
                      <div className="mt-4 content-auth-form">
                        <form id="auth-form">
                          <div className="row auth-form-row">
                            <div className="col-md-12 auth-input-col mb-3">
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`text`}
                                  className="form-control auth-form-control"
                                  id="name"
                                  aria-label="name"
                                  aria-describedby="basic-addon1"
                                  placeholder="Nom "
                                />
                              </div>
                            </div>
                            <div className="col-md-12 auth-input-col mb-3">
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`text`}
                                  className="form-control auth-form-control"
                                  id="ninea"
                                  aria-label="ninea"
                                  aria-describedby="basic-addon1"
                                  placeholder="NINEA "
                                />
                              </div>
                            </div>
                            <div className="col-md-12 auth-input-col mb-3">
                              <div className="form-group auth-form-group auth-form-group-login position-relative">
                              <input type="file" name="file" id="file" className="inputfileJustificatif" />
                              <label htmlFor="file">Uploader le document de l’association</label>
                              <span className="upload-file-auth">
                              <HiOutlineUpload />
                              </span>
                              </div>
                            </div>
                            <div className="col-md-12 auth-submit-btn-container mb-3">
                              <NavLink
                                to="/secteur-activites"
                                className="btn auth-submit-btn-login trans-0-2"
                              >
                                S’inscrire
                              </NavLink>
                            </div>
                            <div className="col-md-12 auth-submit-btn-container">
                            <NavLink
                                to="/verifier-code"
                                className="btn choise-cookies-auth trans-0-2 w-100"
                              >
                                Précédent
                              </NavLink>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JustificatifAssos;
