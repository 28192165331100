import React from "react";
import MyDropzoneFile from "../../../common/MyDropzoneFile";
import { TiCamera } from "react-icons/ti";

function UploadMedia() {
  return (
    <div className="cbii-association-new-challenge-step-container custom-w-50 mx-auto pt-4 row">
      <div className="col-md-12 auth-input-col mb-5">
        <MyDropzoneFile
          label="Uploadez une image de couverture"
          // setFiles={setFiles}
          maxFiles={4}
          isPdfFile={true}
        />
        <div className="text-connect-line-separator pb-2">
          Ou
        </div>
        <button className="btn cbii-new-challenge-btn-use-webcame w-100"><TiCamera style={{ fontSize: "20px" }} /> Utiliser la webcam</button>
      </div>
      <div className="col-md-12 auth-input-col mb-3">
        <MyDropzoneFile
          label="Uploadez le challenge"
          // setFiles={setFiles}
          maxFiles={4}
          isPdfFile={true}
        />
        <div className="text-connect-line-separator pb-2">
          Ou
        </div>
        <button className="btn cbii-new-challenge-btn-use-webcame w-100"><TiCamera style={{ fontSize: "20px" }} /> Utiliser la webcam</button>
      </div>
    </div>
  );
}

export default UploadMedia;
