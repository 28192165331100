import React from "react";
import "./HeaderAdmin.css";
import { useLocation } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import Logo from "../../../../assets/appImages/logo.png";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import { getAvatar } from "../../../../utils/Utils";

import { FiSearch } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import CustomProgressBar from "../../CustomProgressBar";

const HeaderAdmin: React.FC = () => {
  let [showNotifications, setShowNotifications] = React.useState(false);

  let onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const { user } = useAppSelector((s) => s?.user);
  const dispatch = useAppDispatch();
  let location = useLocation();

  const onLogout = () => {
    dispatch(onlogout());
    window.location.href = "/";
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="content-logo-app-haeder-admin">
          <img src={Logo} alt="Logo" className="logo-app-haeder-admin" />
        </div>
        <div className="headering-admin d-flex justify-content-between">
          {location.pathname.endsWith("/association/dashboard") ? (
            <div className="headering-admin-page-title">Dashboard</div>
          ) : location.pathname.endsWith("/association/nouveau-challenge") ? (
            <div className="headering-admin-page-title">Nouveau challenge</div>
          ) : location.pathname.endsWith("/association/challenge-en-cours") ? (
            <div className="headering-admin-page-title">
              Challenges en cours
            </div>
          ) : location.pathname.endsWith("/association/challenge-termine") ? (
            <div className="headering-admin-page-title">
              Challenges terminés
            </div>
          ) : location.pathname.endsWith("/association/rapports") ? (
            <div className="headering-admin-page-title">Rapports</div>
          ) : location.pathname.endsWith("/association/projets/:slug") ? (
            <div className="header-custom-projet-status">
              <div className="header-custom-projet-status-indicator-container">
                <div className="header-custom-projet-status-indicator"></div>
              </div>
              <div className="headering-admin-page-title-custom">
                <div className="headering-admin-page-title">
                  Forage keur massar
                </div>
                <CustomProgressBar
                  value={50}
                  className="completed"
                  showLabel={false}
                />
              </div>
            </div>
          ) : location.pathname.endsWith("/association/projets") ? (
            <div className="headering-admin-page-title">Tous les projets</div>
          ) : (
            <div className="headering-admin-page-title">
              Ice Bucket challenge
            </div>
          )}
          <div className="header-others-items-container">
            <div className="search-form-container">
              <div className="search-form-content">
                <label>
                  <FiSearch />
                </label>
                <input
                  name="search"
                  className="form-control search-input"
                  placeholder="Rechercher..."
                />
              </div>
            </div>
            <div className="header-others-items">
              <div className="container-info-user-connect-header">
                <div className="container-menu-header-admin gap-3">
                  <div
                    className="notification-item-container linkable position-relative"
                    onClick={() => onShowAndHideNotification()}
                  >
                    <div className="notification-item-content">
                      <MdOutlineNotificationsActive className="notif-icon" />
                      <span className="notification-counter">3</span>
                    </div>
                  </div>
                </div>
                <div
                  className="connected-user-container"
                  id="header-user-admin"
                >
                  <div className="connected-user-content ml-auto">
                    <Dropdown className="cbii-header-btn-profile">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="cbii-header-btn-profile-toggle"
                      >
                        <div className="user-acount-dashboard-header-display g-2">
                          <img
                            src={getAvatar(user?.avatar)}
                            className="user-avatar"
                            alt="User Avatar"
                          />
                          <div>
                            <div className="cbii-header-username">Bamba N.</div>
                            <div className="cbii-header-user-occupation">
                              Product manager
                            </div>
                          </div>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">Profile</Dropdown.Item>
                        <Dropdown.Item onClick={() => onLogout()}>
                          Déconnexion
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {showNotifications && (
          <div
            className={
              data?.results?.filter((el) => el?.read !== true)?.length
                ? "header-hiddenable-notification-section"
                : "header-hiddenable-notification-section"
            }
          >
            <ul
              className={
                data?.results?.filter((el) => el?.read !== true)?.length
                  ? "header-hiddenable-notification-ul"
                  : "header-hiddenable-notification-ul"
              }
            >
              {data?.results?.filter((x) => x.read !== true)?.length == 0 ? (
                <li
                  className="header-hiddenable-notification-li"
                  style={{ fontSize: 16 }}
                >
                  <div className="notification-item-link d-flex no-link">
                    <div className="content-text-notif ps-2">
                      <p className="text-notif mb-1">
                        Il n'y a aucune notification .
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                data?.results
                  ?.filter((x) => x.read !== true)
                  ?.map((item) => (
                    <li
                      key={item?.slug}
                      className="header-hiddenable-notification-li"
                      onClick={() => onUpdateReadNotification(item)}
                    >
                      <NavLink
                        to={getRoute(item)}
                        className="notification-item-link d-flex no-link"
                      >
                        <div className="content-text-notif ps-2">
                          <p
                            className="text-notif mb-1"
                            dangerouslySetInnerHTML={createMarkup(
                              item?.content
                            )}
                          />
                          <p className="text-date-notif mb-0">
                            {moment(item?.created_at).format(
                              "DD/MM/yy - HH:mm"
                            )}
                          </p>
                        </div>
                      </NavLink>
                    </li>
                  ))
              )}
            </ul>
          </div>
        )} */}

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <ul className="header-hiddenable-notification-ul">
              <li className="header-hiddenable-notification-li">
                Il n'y a aucune notification .
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;
