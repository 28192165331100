import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./InfoAssociation.css";
import { ColLeftLogin } from "../../auth/LoginAssos";
import { IoIosCloseCircleOutline } from "react-icons/io";

const SecteurActivite = () => {
  const [inputFields, setInputFields] = useState<any>([]);
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  const addFields = (e) => {
    let newfield = { autres: "" };
    e.preventDefault();
    setInputFields([...inputFields, newfield]);
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  return (
    <div className="auth-component">
      <div className="fixed-after-header fixed-login-page-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-6 auth-col auth-left-side-col">
                <ColLeftLogin />
              </div>
              <div className="col-md-6 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="">
                    <div className="auth-form-container">
                      <h1 className="auth-form-title">Secteurs d’activités</h1>
                      <div className="content-text-redirect-auth mt-4">
                        <span className="info-connect-redirection">
                          Veuillez sélectionner vos secteurs d’activités
                        </span>
                      </div>
                      <div className="mt-4 content-auth-form">
                        <form id="auth-form">
                          <div className="auth-form-row">
                            <div className="content-col-add-secteur-radio">
                              <div className="radio-item-container">
                                {Datas.map((item) => (
                                  <div
                                    className="radio-secteur-item-checkbox"
                                    key={item.id}
                                  >
                                    <input
                                      id={`secteur${item.id}`}
                                      type="checkbox"
                                    />
                                    <label htmlFor={`secteur${item.id}`}>
                                      {item.libelle}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {inputFields.map((input, index) => {
                                return (
                                  <div
                                    className="container-form-others-activity mb-2"
                                    key={index}
                                  >
                                    <input
                                      name="autres"
                                      placeholder="Autres"
                                      value={input.autres}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      className="input-form-others-activity"
                                    />
                                    <button
                                      className="btn btn-remove-others-activity"
                                      onClick={() => removeFields(index)}
                                    >
                                      <IoIosCloseCircleOutline />
                                    </button>
                                  </div>
                                );
                              })}
                              <div className="pb-5">
                                <button
                                  className="btn btn-add-others-activity"
                                  onClick={addFields}
                                >
                                  Autres
                                </button>
                              </div>
                            </div>
                            <div className="col-md-6 offset-md-3 auth-submit-btn-container mb-3">
                              <NavLink
                                to="/gestion-des-cookies"
                                className="btn auth-submit-btn-login trans-0-2"
                              >
                                Suivant
                              </NavLink>
                            </div>
                            {/* <div className="col-md-6 offset-md-3 auth-submit-btn-container mb-3">
                              <NavLink
                                to="/"
                                className="btn redirect-text-auth trans-0-2 w-100"
                              >
                                Précédent
                              </NavLink>
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecteurActivite;

const Datas = [
  {
    id: 1,
    libelle: "art",
  },
  {
    id: 2,
    libelle: "accès à l'eau",
  },
  {
    id: 3,
    libelle: "agriculture",
  },
  {
    id: 4,
    libelle: "animaux",
  },
  {
    id: 5,
    libelle: "cuisine",
  },
  {
    id: 6,
    libelle: "culture",
  },
  {
    id: 7,
    libelle: "éducation",
  },
  {
    id: 8,
    libelle: "émancipation des femmes",
  },
  {
    id: 9,
    libelle: "élevage",
  },
  {
    id: 10,
    libelle: "environnement",
  },
  {
    id: 11,
    libelle: "football",
  },
  {
    id: 12,
    libelle: "logement",
  },
  {
    id: 13,
    libelle: "musique",
  },
  {
    id: 14,
    libelle: "mode",
  },
  {
    id: 15,
    libelle: "pêche",
  },
  {
    id: 16,
    libelle: "protection de l'enfance",
  },
  {
    id: 17,
    libelle: "religion",
  },
  {
    id: 18,
    libelle: "santé",
  },
  {
    id: 19,
    libelle: "sport",
  },
  {
    id: 20,
    libelle: "startups",
  },
  {
    id: 21,
    libelle: "voyages",
  },
];
