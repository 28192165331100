import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsPlusSquare } from "react-icons/bs";
import AttachIcon from "../../../../assets/icons/svg/attachsquare.svg";
import CalendarIcon from "../../../../assets/icons/svg/calendarIcon.svg";
import MoreIcon from "../../../../assets/icons/svg/moresquare.svg";
import LinkIcon from "../../../../assets/icons/svg/link.svg";
import AddTaskModal from "../Modals/AddTaskModal";
import DetailsModalTask from "../Modals/DetailsModalTask";

function EtapesTab() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="cbii-details-projet-tab-etapes-accordion-container">
      <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <div className="cbii-details-projet-tab-etapes-accordion-header-container">
            <Accordion.Header>
              <div className="cbii-details-projet-tab-etapes-accordion-header-title todo">
                A faire <span>(02)</span>
              </div>
            </Accordion.Header>
            <button
              className="btn cbii-details-projet-tab-etapes-accordion-header-btn-plus"
              onClick={() => setModalShow(true)}
            >
              <BsPlusSquare />
            </button>
          </div>
          <Accordion.Body className="cbii-details-projet-tab-etapes-accordion-body">
            {[...Array(2)].map((_, index) => (
              <div key={index}>
                <TaskItem index={index} />
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <div className="cbii-details-projet-tab-etapes-accordion-header-container">
            <Accordion.Header>
              <div className="cbii-details-projet-tab-etapes-accordion-header-title in-progress">
                En cours <span>(03)</span>
              </div>
            </Accordion.Header>
            <button
              className="btn cbii-details-projet-tab-etapes-accordion-header-btn-plus "
              onClick={() => setModalShow(true)}
            >
              <BsPlusSquare />
            </button>
          </div>
          <Accordion.Body className="cbii-details-projet-tab-etapes-accordion-body">
            {[...Array(3)].map((_, index) => (
              <div key={index}>
                <TaskItem index={index} />
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <div className="cbii-details-projet-tab-etapes-accordion-header-container">
            <Accordion.Header>
              <div className="cbii-details-projet-tab-etapes-accordion-header-title complete">
                Terminé <span>(04)</span>
              </div>
            </Accordion.Header>
            <button
              className="btn cbii-details-projet-tab-etapes-accordion-header-btn-plus"
              onClick={() => setModalShow(true)}
            >
              <BsPlusSquare />
            </button>
          </div>
          <Accordion.Body className="cbii-details-projet-tab-etapes-accordion-body">
            {[...Array(4)].map((_, index) => (
              <div key={index}>
                <TaskItem index={index} />
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <AddTaskModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

export default EtapesTab;

export function TaskItem({ index }) {
  const [modalShowDetails, setModalShowDetails] = React.useState(false);
  return (
    <>
      <div className="cbii-dashboard-custom-bg custom-border-radius-8 mb-1 pointer">
        <div className="row">
          <div
            className="col-md-3 mb-2"
            onClick={() => setModalShowDetails(true)}
          >
            <div className="cbii-details-projet-tab-etapes-accordion-task-title">
              Aménagement final
            </div>
          </div>
          <div
            className="col-md-6 mb-2"
            onClick={() => setModalShowDetails(true)}
          >
            <div className="cbii-details-projet-tab-etapes-accordion-task-description">
              <p>
                Avant de commencer, une planification détaillée est nécessaire.
                Cela inclut l'évaluation de la géologie, de la topographie et
                des ressources en eau de la zone pour déterminer la f
              </p>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="cbii-details-projet-tab-etapes-accordion-task-stats-container custom-z-index-12">
              {(index === 0 || index === 2) && (
                <div className="cbii-details-projet-tab-etapes-accordion-task-stats custom">
                  <img
                    src={AttachIcon}
                    alt="attach icon"
                    className="cbii-details-projet-tab-etapes-accordion-task-stats-icon"
                  />
                  <span>2</span>
                </div>
              )}
              {index !== 0 && index !== 1 && (
                <div className="cbii-details-projet-tab-etapes-accordion-task-stats custom">
                  <img
                    src={LinkIcon}
                    alt="attach icon"
                    className="cbii-details-projet-tab-etapes-accordion-task-stats-icon"
                  />
                  <span>2</span>
                </div>
              )}
              <div className="cbii-details-projet-tab-etapes-accordion-task-stats">
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  className="cbii-details-projet-tab-etapes-accordion-task-stats-icon"
                />
                <span>Nov. 30</span>
              </div>

              <button className="btn cbii-details-projet-tab-etapes-accordion-header-btn-plus p-0">
                <img
                  src={MoreIcon}
                  alt="more icon"
                  className="cbii-details-projet-tab-etapes-accordion-task-stats-icon"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <DetailsModalTask
        show={modalShowDetails}
        onHide={() => setModalShowDetails(false)}
      />
    </>
  );
}
