import { Navigate } from "react-router-dom";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import DashboardAssociation from "../components/association/DashboardAssociation/DashboardAssociation";
import LoginAssos from "../components/auth/LoginAssos";
import RegisterAssos from "../components/auth/RegisterAssos";
import VerifierCode from "../components/auth/VerifierCode";
import ForgetPasswordAssos from "../components/auth/ForgetPasswordAssos";
import ResetPasswordAssos from "../components/auth/ResetPasswordAssos";
import SecteurActivite from "../components/association/InfoAssociaton/SecteurActivite";
import GestionCookies from "../components/association/InfoAssociaton/GestionCookies";
import ChallengeEncours from "../components/association/ChallengeEncours/ChallengeEncours";
import ProjetsAssociation from "../components/association/Projets/ProjetsAssociation";
import NouveauChallenge from "../components/association/NouveauChallenge/NouveauChallenge";
import ChallengeTerminer from "../components/association/ChallengeTerminer/ChallengeTerminer";
import DetailsChallenge from "../components/association/ChallengeTerminer/DetailsChallenge";
import DetailsProjetsAssociation from "../components/association/Projets/DetailsProjetsAssociation";
import RapportAssociation from "../components/association/Rapports/RapportAssociation";
import TousChallengers from "../components/association/ChallengeTerminer/TousChallengers";
import JustificatifAssos from "../components/auth/JustificatifAssos";
export const AppRoutes = [
  {
    path: "/",
    element: <LoginAssos />,
  },
  {
    path: "/inscription",
    element: <RegisterAssos />,
  },
  {
    path: "/verifier-code",
    element: <VerifierCode />,
  },
  {
    path: "/justificatifs",
    element: <JustificatifAssos />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: <ForgetPasswordAssos />,
  },
  {
    path: "/modifier-mot-de-passe",
    element: <ResetPasswordAssos />,
  },
  {
    path: "/secteur-activites",
    element: <SecteurActivite />,
  },
  {
    path: "/gestion-des-cookies",
    element: <GestionCookies />,
  },
  {
    path: "admin/",
    element: <AdminLayout />,
    children: [
      { element: <Navigate to="dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
    ],
  },
  {
    path: "association/",
    element: <AdminLayout />,
    children: [
      { element: <Navigate to="dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAssociation />,
      },
      {
        path: "challenge-en-cours",
        element: <ChallengeEncours />,
      },
      {
        path: "projets",
        element: <ProjetsAssociation />,
      },
      {
        path: "nouveau-challenge",
        element: <NouveauChallenge />,
      },
      {
        path: "challenge-termine",
        element: <ChallengeTerminer />,
      },
      {
        path: "challenge-en-cours/:slug",
        element: <DetailsChallenge />,
      },
      {
        path: "challenge-termine/:slug",
        element: <DetailsChallenge />,
      },
      {
        path: "projets/:slug",
        element: <DetailsProjetsAssociation />,
      },
      {
        path: "rapports",
        element: <RapportAssociation />,
      },
      {
        path: "tous-les-challengers",
        element: <TousChallengers />,
      },
    ],
  },
];
