import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "@reduxjs/toolkit";
import { UserSlice } from "./slice/User.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { Env, currentEnv } from "../utils/http";
import { UserApi } from "../utils/api/user/user.api";
import { AuthApi } from "../utils/api/auth/auth.api";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    `${UserSlice.name}`,
    `${UserApi.reducerPath}`,
    `${AuthApi.reducerPath}`,
    
  ],
};

export const rootReducers = combineReducers({
  [AuthApi.reducerPath]: AuthApi.reducer,
  [UserSlice.name]: UserSlice.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  
});

const persistedReducer = persistReducer(persistConfig, rootReducers);


const store = configureStore({
  reducer:  {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    
    persistedReducer,
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    AuthApi.middleware,
		UserApi.middleware,
   
  ],
});


setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
