import React from "react";

function SecteurChallenge() {
  return (
    <div className="cbii-association-new-challenge-step-container px-md-5 mx-auto pt-4 row">
      <div className="col-md-12 auth-input-col mb-3">
        <label className={`form-label form-label-cbii-custom `}>
        Sélectionnez les secteurs liés au challenge
        </label>
      </div>
      <div className="col-md-12 auth-input-col mb-3">
      <div className="content-col-add-secteur-radio">
            <div className="radio-item-container py-0">
              {Datas.map((item) => (
                <div
                  className="radio-secteur-item-checkbox-cbii"
                  key={item.id}
                >
                  <input
                    id={`secteur${item.id}`}
                    type="checkbox"
                  />
                  <label htmlFor={`secteur${item.id}`}>
                    {item.libelle}
                  </label>
                </div>
              ))}
            </div>
          </div>
      </div>
    </div>
  );
}

export default SecteurChallenge;

const Datas = [
  {
    id: 1,
    libelle: "art",
  },
  {
    id: 2,
    libelle: "accès à l'eau",
  },
  {
    id: 3,
    libelle: "agriculture",
  },
  {
    id: 4,
    libelle: "animaux",
  },
  {
    id: 5,
    libelle: "cuisine",
  },
  {
    id: 6,
    libelle: "culture",
  },
  {
    id: 7,
    libelle: "éducation",
  },
  {
    id: 8,
    libelle: "émancipation des femmes",
  },
  {
    id: 9,
    libelle: "élevage",
  },
  {
    id: 10,
    libelle: "environnement",
  },
  {
    id: 11,
    libelle: "football",
  },
  {
    id: 12,
    libelle: "logement",
  },
  {
    id: 13,
    libelle: "musique",
  },
  {
    id: 14,
    libelle: "mode",
  },
  {
    id: 15,
    libelle: "pêche",
  },
  {
    id: 16,
    libelle: "protection de l'enfance",
  },
  {
    id: 17,
    libelle: "religion",
  },
  {
    id: 18,
    libelle: "santé",
  },
  {
    id: 19,
    libelle: "sport",
  },
  {
    id: 20,
    libelle: "startups",
  },
  {
    id: 21,
    libelle: "voyages",
  },
];
