import React from 'react'
import { ProjectCard } from './ProjetsEncoursTab'
import { FaSquarePlus } from 'react-icons/fa6'

function ProjetsTerminesTab() {
  return (
    <div className="row">
      {[...Array(4)].map((_, index) => (
        <ProjectCard terminer={true} key={index} />
      ))}
      
    </div>
  )
}

export default ProjetsTerminesTab