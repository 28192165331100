import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { CustomPagination } from '../../../common/CustomPagination';
import { usePagination } from '../../../../utils/hooks';
import wordIcon from "../../../../assets/icons/svg/word.svg";
import pdfIcon from "../../../../assets/icons/svg/pdf.svg";
import excelIcon from "../../../../assets/icons/svg/excel.svg";
import { CiSquareMore } from 'react-icons/ci';
import Dropdown from "react-bootstrap/Dropdown";
import { FaDownload, FaRegTrashAlt } from 'react-icons/fa';

function ListDocumentsTable() {
    const { limit, page, onChange } = usePagination(10);

    const nameFormatter = (cell: any, row: any) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <div className="content-img-profil-praticient-table">
              <img
                src={row.type === "PDF" ? pdfIcon : row.type === "DOC" ? wordIcon : excelIcon}
                className="cbii-icon-doc"
                alt="icon document"
              />
            </div>
            <p className="cbii-table-doc-name mb-0">{row?.nom}</p>
          </div>
        );
      };

      const actionFormatter = (cell: any, row: any) => {
        return (
          <>
            <div className="table-actions-btn-container-commandes d-flex gap-3">
            <Dropdown className="cbii-table-doc-btn-more">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          className="cbii-dashboard-project-btn-action-toggle custom"
                        >
                          <CiSquareMore />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='cbii-table-doc-btn-more-menu'>
                          <Dropdown.Item href="#/action-1" className='cbii-table-doc-btn-more-item'><FaDownload /> Télécharger</Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className='cbii-table-doc-btn-more-item'>
                          <FaRegTrashAlt /> Supprimer
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
              {/* <button className='btn cbii-table-doc-btn-more'><CiSquareMore /></button> */}
            </div>
          </>
        );
      };

    const columns = [
        {
          dataField: "nom",
          text: `Nom`,
          formatter: nameFormatter,
          headerStyle: () => {
            return { width: "300px", whiteSpace: "normal" };
          },
        },
        {
          dataField: "type",
          text: "Type",
        },
        {
          dataField: "taille",
          text: "Taille",
        },
        {
          dataField: "date_ajout",
          text: "Date d'ajout",
        },
        {
          dataField: "",
          text: "Actions",
          formatter: actionFormatter,
        },
      ];
      return (
        <>
              <BootstrapTable
                keyField="id"
                data={data || []}
                columns={columns}
                condensed
                hover
                wrapperClasses="table-responsive admin-table"
                noDataIndication={() => "Aucune donnée trouvée"}
              />
              <div className="custom-pagination-container">
                <CustomPagination
                  nbPages={data?.length}
                  page={page}
                  onChange={onChange}
                  perPage={limit}
                  label="Nombre de fichiers"
                />
              </div>
            </>
       
      );
}

export default ListDocumentsTable

const data = [
    {
        id: 1,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "PDF",
        taille: "2.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 2,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "PDF",
        taille: "2.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 3,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "PDF",
        taille: "2.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 4,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "XLS",
        taille: "5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 5,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "XLS",
        taille: "5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 6,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "DOC",
        taille: "7.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 7,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "DOC",
        taille: "7.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 8,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "DOC",
        taille: "7.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 9,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "DOC",
        taille: "7.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 10,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "DOC",
        taille: "7.5Mo",
        date_ajout: "12/05/2023",
    },
    {
        id: 11,
        nom: "Rapport_Technique_Forage_Geologique",
        type: "DOC",
        taille: "7.5Mo",
        date_ajout: "12/05/2023",
    },
]