import React from "react";
import { useStep } from "react-hooks-helper";
import EtapesTab from "./DetailsProjetsTabs/EtapesTab";
import MediaTab from "./DetailsProjetsTabs/MediaTab";
import DocumentsTab from "./DetailsProjetsTabs/DocumentsTab";
import { useStepType } from "../../../utils/type";

const steps = [
  { id: "etapes", Component: EtapesTab },
  {
    id: "medias",
    Component: MediaTab,
  },
  {
    id: "documents",
    Component: DocumentsTab,
  },
];
const tabs = ["Étapes", "Média", "Documents"];

function DetailsProjetsAssociation() {
  const stats = [
    { label: "Date de début", value: "14.14.2023" },
    { label: "Date de fin", value: "14.14.2024" },
    { label: "Montant", value: "3.440.000 FCFA" },
    { label: "Restant", value: "500.000 FCFA" },
  ];

  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="cbii-dashboard-subtitle mb-3">Vue d’ensemble</div>
            <div className="cbii-dashboard-custom-bg mb-4">
              <div className="projet-stats-container">
                {stats.map((item, index) => (
                  <div key={index} className="projet-stats-item">
                    <div className="projet-stats-label">{item.label}</div>
                    <div className="projet-stats-value">{item.value}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="cbii-dashboard-subtitle mb-2">Description</div>
            <div className="cbii-details-projet-description">
              <p>
                Analytics delivers actionable, industry-ready initiatives each
                time a business complete their full account. Phasellus vitae
                amet amet, mauris faucibus at sit. Pellentesque rhoncus
                adipiscing a enim, quis tortor, non etiam. Eget faucibus mattis
                consequat dui imperdiet scelerisque. Lorem placerat blandit ut
                lobortis volutpat convallis libero. Sed imperdiet dignissim
                ipsum quam.. Analytics delivers actionable, industry-ready
                initiatives each time a business complete their full account.
                Phasellus vitae amet amet, mauris faucibus at sit. Pellentesque
                rhoncus adipiscing a enim, quis tortor, non etiam. Eget faucibus
                mattis consequat dui imperdiet scelerisque. Lorem placerat
                blandit ut lobortis volutpat convallis libero. Sed imperdiet
                dignissim ipsum quam.
              </p>
            </div>
            <div className="cbii-details-projets-tab-container mb-3">
              <ul className="tabs-container parametre-tabs-container-admin mb-0 pt-4 pb-2">
                {tabs.map((tab: any, key: any) => (
                  <li
                    className={`param-tab-item  ${
                      index === key && "active-param-tab"
                    }`}
                    key={key}
                    onClick={() => go(key)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
            <div className="dash-admin-page-content-container mb-3">
              <Component />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsProjetsAssociation;
