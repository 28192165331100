import React from "react";
import { PiDotsThreeOutlineThin } from "react-icons/pi";
import CustomProgressBar from "../../../shared/CustomProgressBar";
import { TbCalendar } from "react-icons/tb";
import Dropdown from "react-bootstrap/Dropdown";
import { FaSquarePlus } from "react-icons/fa6";
import AddProjectModal from "../Modals/AddProjectModal";

function ProjetsEncoursTab() {

  return (
    <div className="row">
      {[...Array(4)].map((_, index) => (
        <ProjectCard key={index} />
      ))}
      
    </div>
  );
}

export default ProjetsEncoursTab;

export function ProjectCard({terminer = false}: {terminer?: boolean}) {
  return (
    <div className="col-md-4 mb-3">
      <div className="cbii-project-card">
        <div className="cbii-project-card-title-container mb-3">
          <div className="cbii-project-card-title">Forage keur massar</div>
          <Dropdown className="cbii-header-btn-profile">
            <Dropdown.Toggle
              id="dropdown-basic"
              className=" btn cbii-project-card-title-btn-action"
            >
              <PiDotsThreeOutlineThin />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Modifier</Dropdown.Item>
              <Dropdown.Item>Supprimer</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <p className="cbii-project-card-description mb-3">
          Quis augue enim a magna feugiat massa, ligula. Proin libero vel in at
          hac. In ipsum, tempor velit, metus. Nibh dolor tortor quam volutpat
          sit.
        </p>
        <div className="cbii-dashboard-last-challenge-card-category-badge-container mb-4">
          <div className="cbii-dashboard-last-challenge-card-category-badge">
            Santé
          </div>
          <div className="cbii-dashboard-last-challenge-card-category-badge">
            Santé
          </div>
        </div>
        {!terminer && <div className="mb-4">
          <CustomProgressBar
            value={50}
            className="completed"
            showLabel={false}
          />
        </div>}
        <div className="w-auto">
          <div className="cbii-project-card-date">
            <TbCalendar />
            <span>Oct - Dec 2021</span>
          </div>
        </div>
      </div>
    </div>
  );
}
