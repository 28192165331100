import { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { ColLeftLogin, OthersLogin } from "./LoginAssos";
const ResetPasswordAssos = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="auth-component">
      <div className="fixed-after-header fixed-login-page-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-6 auth-col auth-left-side-col">
                <ColLeftLogin />
              </div>
              <div className="col-md-6 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="p-5">
                    <div className="auth-form-container">
                      <h1 className="auth-form-title">Nouveau mot de passe</h1>
                      <div className="content-text-redirect-auth mt-4">
                        <span className="info-connect-redirection">
                          Veuillez saisir votre nouveau mot de passe.
                        </span>
                      </div>
                      <div className="mt-4 content-auth-form">
                        <form id="auth-form">
                          <div className="row auth-form-row">
                            <div className="col-md-12 auth-input-col mb-3">
                              <div className="form-group auth-form-group auth-form-group-login position-relative">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control auth-form-control"
                                  id="password"
                                  aria-label="Password"
                                  aria-describedby="basic-addon1"
                                  placeholder="Mot de passe"
                                />
                                <span
                                  className="show-hide-password"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                >
                                  {showPassword ? (
                                    <BsFillEyeSlashFill />
                                  ) : (
                                    <BsFillEyeFill />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12 auth-input-col mb-3">
                              <div className="form-group auth-form-group auth-form-group-login position-relative">
                                <input
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  className="form-control auth-form-control"
                                  id="password"
                                  aria-label="Password"
                                  aria-describedby="basic-addon1"
                                  placeholder="Re-saisir le mot de passe"
                                />
                                <span
                                  className="show-hide-password"
                                  onClick={() => {
                                    setShowConfirmPassword(
                                      !showConfirmPassword
                                    );
                                  }}
                                >
                                  {showConfirmPassword ? (
                                    <BsFillEyeSlashFill />
                                  ) : (
                                    <BsFillEyeFill />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12 auth-submit-btn-container">
                              <NavLink
                                to="/"
                                className="btn auth-submit-btn-login trans-0-2"
                              >
                                Confirmer
                              </NavLink>
                            </div>
                            <div className="d-flex justify-content-center my-4">
                              <NavLink to="/" className="redirect-text-auth">
                                Retour à la page de connexion
                              </NavLink>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="content-text-redirect-auth mt-4">
                      <span className="info-connect-redirection">
                        Pas encore de compte ?
                      </span>
                      <NavLink to="/" className="redirect-text-auth">
                        Inscrivez-vous !
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordAssos;
