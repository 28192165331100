import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./InfoAssociation.css";
import { ColLeftLogin } from "../../auth/LoginAssos";

const GestionCookies = () => {
  return (
    <div className="auth-component">
      <div className="fixed-after-header fixed-login-page-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-6 auth-col auth-left-side-col">
                <ColLeftLogin />
              </div>
              <div className="col-md-6 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="">
                    <div className="auth-form-container">
                      <h1 className="auth-form-title">Gestion des cookies</h1>
                      <div className="content-text-redirect-auth mt-4">
                        <span className="info-connect-redirection">
                          Nous utilisons des{" "}
                          <span
                            style={{
                              color: "#6741FF",
                              textDecoration: "underline",
                            }}
                          >
                            cookies
                          </span>{" "}
                          pour assurer les fonctionnalités de base du site Web
                          et pour améliorer votre expérience. Vous pouvez les
                          paramétrer à votre convenance.
                        </span>
                      </div>
                      <div className="mt-4 content-auth-form">
                        <form id="auth-form">
                          <div className="auth-form-row">
                            <div className="content-col-add-secteur-radio">
                              <div className="check-cookies-item-container">
                                {Datas.map((item) => (
                                  <div className="container-cookies-item">
                                    <div className="content-text-cookies-item">
                                      <p className="title-cookies-item">
                                        {item.title}
                                      </p>
                                      <p className="text-cookies-item">
                                        {item.text}
                                      </p>
                                    </div>
                                    <div className="content-switch-cookies">
                                      <label className="switch-btn">
                                        <input type="checkbox" />
                                        <span></span>
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-6 offset-md-3 auth-submit-btn-container mb-3 pt-5">
                              <NavLink
                                to="/association/dashboard"
                                className="btn auth-submit-btn-login trans-0-2"
                              >
                                Tout accepter
                              </NavLink>
                            </div>
                            <div className="col-md-6 offset-md-3 auth-submit-btn-container mb-3">
                              <NavLink to="/association/dashboard" className="btn choise-cookies-auth trans-0-2">
                                Confirmer mes choix
                              </NavLink>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionCookies;

const Datas = [
  {
    id: 1,
    title: "Le strict minimum (obligatoire)",
    text: "Fonctionnement essentiel de l'application.",
  },
  {
    id: 2,
    title: "Cookies de Performance",
    text: "Analyse anonyme pour des améliorations.",
  },
  {
    id: 3,
    title: "Cookies de Personnalisation",
    text: "Pour du contenu adapté à vos intérêts.",
  },
  {
    id: 4,
    title: "Cookies Publicitaires",
    text: "Pour des publicités pertinentes pour vous.",
  },
];
