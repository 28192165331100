import React from "react";
import { LuShare2 } from "react-icons/lu";
import { RxDotFilled } from "react-icons/rx";
import ParticipantCounter from "../DashboardAssociation/components/ParticipantCounter";
import Challeng1 from "../../../assets/appImages/challenge.png";
import { useStep } from "react-hooks-helper";
import ParticipationTab from "./DetailsChallengeTab/ParticipationTab";
import DetailsTab from "./DetailsChallengeTab/DetailsTab";
import Eticket from "../../../assets/appImages/eticket.png";
import BarChartAssociation from "./BarChart/BarChartAssociation";
import avatar from "../../../assets/appImages/Oval.png";
import { TiHeartFullOutline } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useStepType } from "../../../utils/type";

const steps = [
  { id: "participations", Component: ParticipationTab },
  {
    id: "details",
    Component: DetailsTab,
  },
];
const tabs = ["Participations", "Détails"];

function DetailsChallenge() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  let location = useLocation();
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="container-bg-dashboard-challenge-en-cours">
              <div className="row row-challenge-en-cours custom-mt-30 position-relative">
                <div className="col-md-8 mb-3">
                  <div className="cbii-dashboard-custom-bg  custom-px-50 ">
                    <div className="details-challenge-img-eticket-container">
                      <img
                        src={Eticket}
                        alt="eticket"
                        className="details-challenge-img-eticket"
                      />
                    </div>
                    <div className="cbii-details-challenge-page-title-container">
                      <div className="cbii-details-challenge-page-title-item">
                        <div className="cbii-details-challenge-page-title mb-2">
                          Ice Bucket challenge
                        </div>
                        <div className="cbii-details-challenge-date">
                          <span>Posté il y’a 2 jours</span>
                          <span>
                            <RxDotFilled />
                          </span>
                          <span>5 jours restants</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <button className="btn cbii-details-challenge-btn-share">
                          <LuShare2 />
                        </button>
                        {location?.pathname?.startsWith("/association/challenge-termine") && <button className="btn cbii-details-challenge-btn-compte-rendu">Compte rendu</button>}
                      </div>
                    </div>
                    <div className="cbii-details-challenge-participant-counter">
                      <ParticipantCounter
                        count={250}
                        challengeIcon={Challeng1}
                      />
                    </div>
                    <div>
                      <p className="cbii-details-challenge-description">
                        Le Challenge consiste à se renverser ou se faire
                        renverser un seau d’eau glacée sur la tête pour
                        sensibiliser à la sclérose latérale amyotrophique (SLA)
                        également appelée maladie de Charcot. Prêt(e)s pour un
                        défi rafraîchissant ? Joignez-vous à moi pour le Ice
                        Bucket Challenge ! On verse un seau d'eau glacée sur
                        nous, on filme et on nomine 3 potes pour faire de même !
                      </p>
                    </div>

                    <div className="mb-4">
                      <ul className="tabs-container parametre-tabs-container-admin mb-0 pt-4 pb-2 border-top-0  gap-0">
                        {tabs.map((tab: any, key: any) => (
                          <li
                            className={`param-tab-item w-50  ${
                              index === key && "active-param-tab"
                            }`}
                            key={key}
                            onClick={() => go(key)}
                          >
                            {tab}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="dashbord-admin-component">
                      <div className="dash-admin-page-content-container mb-3">
                        <Component />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="cbii-dashboard-custom-bg mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <div className="cbii-details-challenge-chart-label">
                          Fond levés
                        </div>
                        <div className="cbii-dashboard-subtitle">
                          500.000 FCFA
                        </div>
                      </div>
                      <select className="form-control from-control-graph custom">
                        <option value="patients" selected>
                          Par semaine
                        </option>
                        <option value="medecins">Par mois</option>
                        <option value="rdvs">Par année</option>
                      </select>
                    </div>
                    <BarChartAssociation />
                  </div>
                  {location?.pathname?.startsWith("/association/challenge-termine") && <div className="cbii-dashboard-custom-bg mb-3">
                    <div className="cbii-details-challenge-challengers mb-4">
                      <div className="cbii-details-challenge-challengers-title">Vainqueurs</div>
                      <NavLink to={"/association/tous-les-challengers"} className="btn cbii-details-challenge-challengers-btn">Tout voir</NavLink>
                    </div>
                    {[...Array(3)].map((_, index) => (
                      <div className="mb-3" key={index}>
                        <ChallengerItem withBadge={true} />
                      </div>
                    ))}
                  </div>}
                  <div className="cbii-dashboard-custom-bg">
                    <div className="cbii-details-challenge-challengers mb-4">
                      <div className="cbii-details-challenge-challengers-title">Challengers</div>
                      <NavLink to={"/association/tous-les-challengers"} className="btn cbii-details-challenge-challengers-btn">Tout voir</NavLink>
                    </div>
                    {[...Array(5)].map((_, index) => (
                      <div className="mb-3" key={index}>
                        <ChallengerItem />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsChallenge;

export function ChallengerItem({ withBadge }: {withBadge?: boolean}) {
  return (
    <div className="custom-challenge-winner">
      <div className="cbii-details-challenge-card-participation-item-user-infos-container">
        <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
          <img
            src={avatar}
            alt="user avatar"
            className="cbii-details-challenge-card-participation-item-user-avatar"
          />
        </div>
        <div className="cbii-details-challenge-card-participation-item-user-name-container">
          <div className="cbii-details-challenge-card-participation-item-user-name">
            That Boy Dudu
          </div>
          <div className="cbii-details-challenge-card-participation-item-user-pseudo">
            @dudufaitdesvideos
          </div>
        </div>
      </div>
      {withBadge && <div className="cbii-challenge-item-badge">135 <TiHeartFullOutline /></div>}
    </div>
  )
}
