import React from "react";
import { PiDotsThreeOutline } from "react-icons/pi";
import avatar from "../../../../assets/appImages/Oval.png";
import PostImg from "../../../../assets/appImages/post.png";
import ImageWithOverlayText from "../ImageWithOverlayText";
import { GoHeart } from "react-icons/go";
import { FiSend } from "react-icons/fi";
import { Input } from "../../../common/Input";
import DetailsChallengeModal from "../Modals/DetailsChallengeModal";

function ParticipationTab() {
  return (
    <div className="custom-w-75 mx-auto">
      {[...Array(3)].map((_, index) => (
        <div className="mb-3" key={index}>
          <ParticipationCardPost />
        </div>
      ))}
    </div>
  );
}

export default ParticipationTab;

export function ParticipationCardPost() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="cbii-details-challenge-card-participation-item">
        <div
          className="cbii-details-challenge-card-participation-item-header"
          onClick={() => setModalShow(true)}
        >
          <div className="cbii-details-challenge-card-participation-item-user-infos-container">
            <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
              <img
                src={avatar}
                alt="user avatar"
                className="cbii-details-challenge-card-participation-item-user-avatar"
              />
            </div>
            <div className="cbii-details-challenge-card-participation-item-user-name-container">
              <div className="cbii-details-challenge-card-participation-item-user-name">
                That Boy Dudu
              </div>
              <div className="cbii-details-challenge-card-participation-item-user-pseudo">
                @dudufaitdesvideos
              </div>
            </div>
          </div>
          <button className="btn cbii-details-challenge-card-participation-item-btn-more">
            <PiDotsThreeOutline style={{ fontSize: "22px" }} />
          </button>
        </div>
        <ImageWithOverlayText
          imageSrc={PostImg}
          overlayText="Woo-hoo ! Je viens de relever le défi du Bucket Challenge et c'était génial ! C'était à la fois rafraîchissant et amusant, et surtout, je sais que nous contribuons à une cause importante..."
        />
        <div className="cbii-details-challenge-card-participation-item-footer">
          <div className="cbii-details-challenge-card-participation-item-footer-input-container mb-3">
            <button className="btn btn-fav-comment">
              <GoHeart style={{ fontSize: "35px" }} />
            </button>
            <Input
              type="text"
              id="commentaire"
              placeholder="Commentaire"
              aria-label="commentaire"
              customClassContainer={"w-100 mb-0"}
              customClassInput="input-modal-form-add-commentaire"
            />
            <button className="btn btn-send-comment">
            <FiSend style={{ fontSize: "35px" }} />
            </button>
          </div>
          <div className="cbii-details-challenge-card-participation-item-footer-stats">
            <div className="cbii-details-challenge-card-participation-item-footer-stats-reactions">
              <div className="cbii-details-challenge-card-participation-item-footer-stats-reactions-icon-container">
                <span>😩</span>
                <span>😍</span>
                <span>😆</span>
              </div>
              23 Réactions
            </div>
            <div className="cbii-details-challenge-card-participation-item-footer-stats-commentaires">
              3 commentaires
            </div>
          </div>
        </div>
      </div>
      <DetailsChallengeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
