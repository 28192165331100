import React, { useState } from "react";
import { Input } from "../../../common/Input";
import MediaSelector from "../MediaSelector"

function Infoschallenge() {
  const [selectedMedia, setSelectedMedia] = useState('photos');
  return (
    <div className="cbii-association-new-challenge-step-container custom-w-50 mx-auto pt-4 row">
      <div className="col-md-12 auth-input-col">
        <Input
          type="text"
          id="titre"
          placeholder="Titre"
          aria-label="titre"
          customClassLabel={"input-modal-label-form-add"}
          customClassInput="input-modal-form-add"
        />
      </div>
      <div className="col-md-12 auth-input-col mb-3">
        <textarea
          className="form-control input-modal-form-add-textarea"
          id="description"
          placeholder="Description"
          rows={4}
        ></textarea>
      </div>
      <div className="col-md-12 auth-input-col mb-4">
        <textarea
          className="form-control input-modal-form-add-textarea"
          id="objectif"
          placeholder="Objectif"
          rows={4}
        ></textarea>
      </div>
      <div className="col-md-12 auth-input-col mb-3">
        <label className={`form-label form-label-cbii-custom `}>
          Mode de participation
        </label>
        <div style={{ padding: '0px 16px' }}>
          <MediaSelector selected={selectedMedia} onSelect={setSelectedMedia} />
        </div>
      </div>
    </div>
  );
}

export default Infoschallenge;
