import React from "react";
import Modal from "react-bootstrap/Modal";
import { RxCalendar } from "react-icons/rx";
import { SelectInput } from "../../../common/SelectInput";
import { MdOutlineFilterList } from "react-icons/md";
import { Input } from "../../../common/Input";
import { FiSend } from "react-icons/fi";
import avatar from "../../../../assets/appImages/Oval.png";
import GalleryIcon from "../../../../assets/icons/svg/gallery.svg";
import FileIcon from "../../../../assets/icons/svg/fileIcon.svg";

function DetailsModalTask(props) {
  const statutOption = [
    { label: "En cours", value: 1 },
    { label: "Terminé", value: 2 },
  ];
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="cbii-modal-add-project-header px-4 d-flex justify-content-center border-0">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="cbii-modal-add-project-title"
        >
          Planification et étude de faisabilité
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="cbii-modal-details-task-card-stats">
              <div className="cbii-modal-details-task-card-stats-label mb-4">
                Création
              </div>
              <div className="cbii-modal-details-task-card-stats-value">
                <RxCalendar /> 10 Novembre
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="cbii-modal-details-task-card-stats">
              <div className="cbii-modal-details-task-card-stats-label mb-4">
                Délai
              </div>
              <div className="cbii-modal-details-task-card-stats-value">
                <RxCalendar /> 30 Novembre
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="cbii-modal-details-task-card-stats">
              <div className="cbii-modal-details-task-card-stats-label mb-3">
                Statut
              </div>
              <SelectInput
                hiddenFirstOption
                options={statutOption}
                customClassInput={
                  "auth-form-control-add h-50 custom-select-padding"
                }
              />
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="cbii-modal-details-task-description-title mb-3">Description</div>
            <div className="cbii-modal-details-task-description">
                <p>Analytics delivers actionable, industry-ready initiatives each time a business complete their full account. Phasellus vitae amet amet, mauris faucibus at sit. Pellentesque rhoncus adipiscing a enim, quis tortor, non etiam. Eget faucibus mattis consequat dui imperdiet scelerisque. Lorem placerat blandit ut lobortis volutpat convallis libero. Sed imperdiet dignissim ipsum quam.</p>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="cbii-modal-details-task-activité-title-container">
                <div className="cbii-modal-details-task-description-title">Activité</div>
                <button className="cbii-modal-details-task-btn-filter"><MdOutlineFilterList /> Les plus récents</button>
            </div>
          </div>

          <div className="cbii-details-challenge-card-modal-commentaire-item-container">
                {[...Array(3)].map((_, index) => (
                  <div className="mb-3" key={index}>
                    <TaskCommentaireItem />
                  </div>
                ))}
              </div>
              <div className="cbii-details-challenge-card-modal-footer">
                <div className="cbii-details-challenge-card-participation-item-footer-input-container">
                  <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
                    <img
                      src={avatar}
                      alt="user avatar"
                      className="cbii-details-challenge-card-participation-item-user-avatar"
                    />
                  </div>
                  <Input
                    type="text"
                    id="commentaire"
                    placeholder="Insérez votre commentaire..."
                    aria-label="commentaire"
                    customClassContainer={"w-100 mb-0"}
                    customClassInput="input-modal-form-add-commentaire"
                  />
                  <FiSend style={{ fontSize: "35px" }} />
                </div>
              </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DetailsModalTask;

export function TaskCommentaireItem() {
    return (
      <div className="cbii-details-challenge-card-modal-commentaire-item">
        <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
          <img
            src={avatar}
            alt="user avatar"
            className="cbii-details-challenge-card-participation-item-user-avatar"
          />
        </div>
        <div className="cbii-details-challenge-card-modal-commentaire-item-content-container w-100">
          <div className="cbii-details-challenge-card-modal-commentaire-item-content">
            <div className="cbii-details-challenge-card-participation-item-user-name mb-4">
              Bamba Ndiaye
            </div>
            <div className="cbii-details-challenge-card-modal-commentaire-item-para mb-3">
              <p>
              Analytics delivers actionable, industry-ready initiatives each time a business complete their full account. Phasellus vitae amet amet, mauris faucibus at sit. Pellentesque rhoncus adipiscing a enim, quis tortor, non etiam. Eget faucibus mattis consequat dui imperdiet scelerisque. Lorem placerat blandit ut lobortis volutpat convallis libero. Sed imperdiet dignissim ipsum quam.
              </p>
            </div>
            <div className="cbii-details-challenge-card-participation-item-footer-stats-reactions gap-3">
                {[...Array(2)].map((_, index) => (
                    <div className="cbii-details-task-modal-attach-file">
                        <div className="cbii-details-task-modal-attach-file-img-container">
                            <img src={index === 0 ? FileIcon : GalleryIcon} alt="file icon" className="cbii-details-task-modal-attach-file-img" />
                        </div>
                        <div className="cbii-details-task-modal-attach-file-name-container">
                            <div className="cbii-details-task-modal-attach-file-name">{index === 0 ? "facture.pdf" : "facade.jpg"}</div>
                            <div className="cbii-details-task-modal-attach-file-weigth">50 kb</div>
                        </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }