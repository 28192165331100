
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getFileSizeInMo } from "../../utils/Utils";
import { FaFilePdf } from "react-icons/fa";
import photoImage from "../../assets/icons/photo-dropzone.png";
import videoImage from "../../assets/icons/video-dropzone.png";
import ErrorMessage from "./ErrorMessage";
import { BiTrash } from "react-icons/bi";

function MyDropzoneFile({
  setFiles,
  label,
  maxFiles = 4,
  isPdfFile = false,
}: {
  setFiles?: any,
  label?: string,
  maxFiles?: number,
  isPdfFile?: boolean;
}) {
  const [maxFileError, setMaxFileError] = useState<string>("");
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any[]) => {
    if (acceptedFiles) {
        setFiles((prevFiles: []) => {
          // console.log([...prevFiles, ...acceptedFiles]);
          return [...prevFiles, ...acceptedFiles];
        });

        console.log('acceptedFiles: ', acceptedFiles);
      }
      fileRejections.forEach((file: { errors: any[] }) => {
        file.errors.forEach((err: { code: string }) => {
          if (err.code === "too-many-files") {
            setMaxFileError("Maximum 4 fichier");
          }
          if (err.code === "file-invalid-type") {
            setMaxFileError("Types de fichiers non prises en charge");
          }
        });
      });
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    maxSize: 109715200,
    accept: isPdfFile
      ? { "application/pdf": [] }
      : { "application/*": [] },
  });

  const fileRejectionItems = fileRejections.map(({ errors }, index) => {
    return (
      <div key={index}>
        <ul className="p-0">
          {errors.map((e) => (
            <li
              key={e.code}
              style={{ listStyle: "none" }}
              className="text-danger"
            >
              {e.code === "file-too-large" &&
                `Le fichier ${index + 1} est trop grand`}
            </li>
          ))}
        </ul>
      </div>
    );
  });

  useEffect(() => {
    setTimeout(() => setMaxFileError(""), 3000);
  });
  return (
    <section className="dropzone files_dropzone">
      {!!label && (
        <label htmlFor="patiente-files" className="form-label form-label-cbii-custom mb-2">
            {label}
        </label>
      )}
      <div 
        {...getRootProps()}
        style={{
          border: "2px solid #6741FF",
          overflow: "hidden",
          opacity: 1,
          borderRadius: "16px",
          display: "flex",
          justifyContent: "center",
          padding: "16px 24px",
          background: "var(--background-color)",
          color: "#A6A6A6"
        }}
      >
        <input
          {...getInputProps()}
          type="file"
          id="__yad_patient_files"
          name="yad-patient-files"
          accept="application/pdf"
        />
        <div className="start-label-input-choose-file text-center">
          <div className="flex-c align-items-center mb-3 gap-3">
            {/* {!!isPdfFile && (
              <>
                <FaFilePdf style={{ color: "#9203D7", fontSize: "30px" }} />
              </>
            )} */}
            <div className="d-flex align-items-center gap-4 justify-content-center">
              <img src={photoImage} alt="" />
              <img src={videoImage} alt="" />
            </div>
          </div>
          <span className="first-label-input-choose-file">
            Selectionnez depuis la galerie
          </span>{" "}
          {/* {!!isPdfFile && (
            <span className="second-label-input-choose-file">
              Format acceptés : PDF d'au plus 10Mo
            </span>
          )} */}
        </div>
      </div>
      {maxFileError && <ErrorMessage message={maxFileError} />}
      <div className="row pt-2">
        {/* {acceptedFileItems} */}
        {!maxFileError && fileRejectionItems}
      </div>
    </section>
  );
}

export default MyDropzoneFile;


export const RenderFile = ({
  files,
  isPdfFile = false,
  deleteFile,
}: {
  files: File[] | [],
  isPdfFile?: boolean,
  deleteFile: (i: number, file: any) => void;
}) => {
    // console.log('files: ', files);
  return (
    <>
        <div className="file-render-grid">
            <div className="row grid-row">
                {files?.length ?
                 files.map((file: any, _id: number) => (
                    <div className="col-md-4 mb-3" key={`file_${_id}`}>
                        <div className="d-flex gap-3 align-items-center justify-content-content">
                            <div className="d-flex gap-2 align-items-center">
                                {!!isPdfFile && (
                                    <img src={photoImage} alt="pdf" className="pdf_file" />
                                )}
                                <div className="content-format-file">
                                    <p className="name-sdoc-format mb-1">
                                      {!!file &&
                                      <strong>{file?.picture?.substr(-15)}</strong>}
                                    </p>
                                    <p>{getFileSizeInMo(file)}</p>
                                </div>
                            </div>
                            <button
                                className="btn with-tooltip btn-action-icon-delete mt-2"
                                title="Supprimer"
                                data-tooltip-content="Supprimer"
                                type="button"
                                onClick={() => deleteFile(_id, file)}
                            >
                                <BiTrash color="#ff0000" size={22} />
                            </button>
                        </div>
                    </div>
                ))
                : 
                ""}
            </div>
        </div>
    </>
   
  );
};
