import React from 'react'
import GalleryComponent from '../GalleryComponent'

function MediaTab() {
  return (
    <div className='cbii-details-projet-tab-media'>
      <div className='cbii-details-projet-tab-media-title-container mb-3'>
        <div className='cbii-details-projet-tab-media-title'>Total média</div>
        <div className='cbii-details-projet-tab-media-subtitle'>42 images, 60 vidéos</div>
      </div>
      <GalleryComponent />
    </div>
  )
}

export default MediaTab