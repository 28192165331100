import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./LoginAssos.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Carousel from "react-bootstrap/Carousel";
import ImgColLeft from "../../assets/appImages/img-col-left.png";
const LoginAssos = () => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="auth-component">
      <div className="fixed-after-header fixed-login-page-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-6 auth-col auth-left-side-col">
                <ColLeftLogin />
              </div>
              <div className="col-md-6 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="p-5">
                    <div className="auth-form-container">
                      <h1 className="auth-form-title">Connexion</h1>
                      <div className="content-text-redirect-auth mt-4">
                        <span className="info-connect-redirection">
                          Pas encore de compte ?
                        </span>
                        <NavLink
                          to="/inscription"
                          className="redirect-text-auth"
                        >
                          Inscrivez-vous !
                        </NavLink>
                      </div>
                      <div className="mt-4 content-auth-form">
                        <form id="auth-form">
                          <div className="row auth-form-row">
                            <div className="col-md-12 auth-input-col mb-3">
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`email`}
                                  className="form-control auth-form-control"
                                  id="email"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  placeholder="E-mail"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 auth-input-col">
                              <div className="form-group auth-form-group auth-form-group-login position-relative">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control auth-form-control"
                                  id="password"
                                  aria-label="Password"
                                  aria-describedby="basic-addon1"
                                  placeholder="Mot de passe"
                                />
                                <span
                                  className="show-hide-password"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                >
                                  {showPassword ? (
                                    <BsFillEyeSlashFill />
                                  ) : (
                                    <BsFillEyeFill />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="content-forget-password-link mb-4">
                              <NavLink
                                to="/mot-de-passe-oublie"
                                className="forget-password-link"
                              >
                                Mot de passe oublie ?
                              </NavLink>
                            </div>

                            <div className="col-md-12 auth-submit-btn-container">
                              <NavLink
                                className="btn auth-submit-btn-login trans-0-2"
                                to="/secteur-activites"
                              >
                                Se connecter
                              </NavLink>
                            </div>
                            <OthersLogin />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAssos;
export const ColLeftLogin = () => {
  return (
    <div className="content-title-auth-col-left">
      <Carousel>
        <Carousel.Item>
          <div className="content-img-col-left-login">
            <img
              src={ImgColLeft}
              alt="ImgColLeftSlide"
              className="col-left-login"
            />
          </div>
          <Carousel.Caption>
            <h3 className="title-slider-col-left">
              Challenge B, soutien inconditionnel à vos initiatives !
            </h3>
            <p className="text-slider-col-left">
            Avec Challenge B, amplifiez l’impact durable de vos initiatives
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="content-img-col-left-login">
            <img
              src={ImgColLeft}
              alt="ImgColLeftSlide"
              className="col-left-login"
            />
          </div>
          <Carousel.Caption>
            <h3 className="title-slider-col-left">
              Challenge B, soutien inconditionnel à vos initiatives !
            </h3>
            <p className="text-slider-col-left">
            Transparence garantie, confiance renforcée : Challenge B booste l’engagement de votre communauté
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="content-img-col-left-login">
            <img
              src={ImgColLeft}
              alt="ImgColLeftSlide"
              className="col-left-login"
            />
          </div>
          <Carousel.Caption>
            <h3 className="title-slider-col-left">
              Challenge B, soutien inconditionnel à vos initiatives !
            </h3>
            <p className="text-slider-col-left">
            Challenge B, votre allié pour un reporting qui affirme et renforce votre crédibilité
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export const OthersLogin = () => {
  return (
    <>
      <div className="text-connect-line-separator my-5">
        Ou se connecter avec
      </div>
      <div className="container-others-login-app">
        <div className="content-others-login-app-item">
          <NavLink className="btn btn-link-to-others-app-login" to="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <g clip-path="url(#clip0_1_8290)">
                <path
                  d="M0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M16.0904 8.70089C18.2435 8.70089 19.6961 9.63102 20.5242 10.4082L23.7604 7.24846C21.7728 5.40098 19.1864 4.26709 16.0904 4.26709C11.6056 4.26709 7.73231 6.84073 5.84668 10.5865L9.55432 13.466C10.4843 10.7012 13.058 8.70089 16.0904 8.70089Z"
                  fill="#E8503A"
                />
                <path
                  d="M27.097 15.9879C27.097 15.045 27.0206 14.357 26.8549 13.6436H16.0889V17.899H22.4084C22.2809 18.9564 21.5929 20.5491 20.064 21.6193L23.6824 24.4224C25.8484 22.4219 27.097 19.4788 27.097 15.9879Z"
                  fill="#6741FF"
                />
                <path
                  d="M9.56852 18.0002C9.32639 17.2868 9.18625 16.5222 9.18625 15.7324C9.18625 14.9424 9.32639 14.1779 9.55573 13.4645L5.84809 10.585C5.07099 12.1394 4.625 13.8849 4.625 15.7324C4.625 17.5797 5.07099 19.3253 5.84809 20.8796L9.56852 18.0002Z"
                  fill="#F4983C"
                />
                <path
                  d="M16.0903 27.1999C19.1863 27.1999 21.7855 26.1807 23.6839 24.4224L20.0654 21.6194C19.0971 22.2946 17.7976 22.7661 16.0903 22.7661C13.058 22.7661 10.4844 20.7657 9.56701 18.001L5.85938 20.8804C7.745 24.6263 11.6056 27.1999 16.0903 27.1999Z"
                  fill="#24D164"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_8290">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </NavLink>
        </div>
        <div className="content-others-login-app-item">
          <NavLink className="btn btn-link-to-others-app-login" to="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <g clip-path="url(#clip0_1_8296)">
                <path
                  d="M0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16Z"
                  fill="#121212"
                />
                <path
                  d="M15.3857 11.123C14.9065 10.9035 14.3903 10.667 13.527 10.667C10.3054 10.667 9.50002 13.9613 9.5 17.2552C9.49998 20.5491 11.9162 24.6669 13.527 24.6669C14.4706 24.6669 15.1378 24.3844 15.6906 24.1503C16.0814 23.9847 16.415 23.8434 16.7486 23.8434C17.0822 23.8434 17.4158 23.9847 17.8067 24.1503C18.3594 24.3844 19.0266 24.6669 19.9703 24.6669C21.1201 24.6669 22.6805 22.5687 23.4793 20.1694C23.5597 19.9278 23.3956 19.6786 23.1547 19.612C21.7841 19.2332 20.7757 17.9529 20.7757 16.4317C20.7757 14.9963 21.6736 13.7752 22.9265 13.3239C23.1644 13.2382 23.3112 12.9718 23.1991 12.7407C22.5959 11.4971 21.5875 10.667 19.9703 10.667C19.107 10.667 18.5908 10.9035 18.1115 11.123C17.6965 11.3131 17.3094 11.4905 16.7486 11.4905C16.1879 11.4905 15.8008 11.3131 15.3857 11.123Z"
                  fill="white"
                />
                <path
                  d="M15.3335 9.28124C15.3335 7.46906 16.8025 6 18.6147 6C18.7356 6 18.8335 6.09794 18.8335 6.21875C18.8335 8.03093 17.3645 9.49999 15.5522 9.49999C15.4314 9.49999 15.3335 9.40205 15.3335 9.28124Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_8296">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </NavLink>
        </div>
        <div className="content-others-login-app-item">
          <NavLink className="btn btn-link-to-others-app-login" to="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <g clip-path="url(#clip0_1_8302)">
                <path
                  d="M32 16C32 7.16253 24.8375 0 16 0C7.16253 0 0 7.16253 0 16C0 23.9875 5.85 30.6063 13.5 31.8063V20.625H9.43747V16H13.5V12.475C13.5 8.4656 15.8875 6.25 19.5437 6.25C21.2937 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1063C19.1187 10.5 18.5 11.7344 18.5 13V16H22.9375L22.2281 20.625H18.5V31.8063C26.15 30.6063 32 23.9875 32 16Z"
                  fill="#6741FF"
                />
                <path
                  d="M22.2282 20.625L22.9375 16H18.5V13C18.5 11.7344 19.1188 10.5 21.1063 10.5H23.125V6.5625C23.125 6.5625 21.2938 6.25 19.5438 6.25C15.8876 6.25 13.5 8.4656 13.5 12.475V16H9.4375V20.625H13.5V31.8063C14.3156 31.9344 15.15 32 16 32C16.85 32 17.6844 31.9344 18.5 31.8063V20.625H22.2282Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_8302">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </NavLink>
        </div>
      </div>
    </>
  );
};
