import React from "react";
import Eticket from "../../../assets/appImages/eticket.png";
import { RxDotFilled } from "react-icons/rx";
import { LuShare2 } from "react-icons/lu";
import { ChallengerItem } from "./DetailsChallenge";

function TousChallengers() {
  return (
    <div className="cbii-details-projet-tab-documents">
      <div className="cbii-details-projet-tab-documents-header">
        <div className="cbii-details-projet-tab-media-title-container mb-3">
          <div className="cbii-details-projet-tab-media-title">
            Tous les challengers
          </div>
          <div className="cbii-details-projet-tab-media-subtitle">
            123 personnes
          </div>
        </div>
      </div>
      <div className="container-bg-dashboard-challenge-en-cours">
        <div className="row row-challenge-en-cours custom-mt-30 position-relative">
          <div className="col-md-4 mb-3">
            <div className="cbii-dashboard-custom-bg  custom-px-50 ">
              <div className="details-challenge-img-eticket-container">
                <img
                  src={Eticket}
                  alt="eticket"
                  className="details-challenge-img-eticket"
                />
              </div>
              <div className="cbii-details-challenge-page-title-container">
                <div className="cbii-details-challenge-page-title-item">
                  <div className="cbii-details-challenge-page-title mb-2">
                    Ice Bucket challenge
                  </div>
                  <div className="cbii-details-challenge-date">
                    <span>Posté il y’a 2 jours</span>
                    <span>
                      <RxDotFilled />
                    </span>
                    <span>5 jours restants</span>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <button className="btn cbii-details-challenge-btn-share">
                    <LuShare2 />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 mb-3">
            <div className="">
              {[...Array(10)].map((_, index) => (
                <div className="mb-3" key={index}>
                  <ChallengerItem />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TousChallengers;
