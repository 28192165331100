import React from "react";
import { CiHeart } from "react-icons/ci";
import ParticipantCounter from "./ParticipantCounter";
import { NavLink, useLocation } from "react-router-dom";

const LastChallengeCard = ({ challenge }) => {
  let location = useLocation();
  return (
    <NavLink
      to={
        location?.pathname?.startsWith("/association/challenge-termine")
          ? "/association/challenge-termine/:slug"
          : "/association/challenge-en-cours/:slug"
      }
      className="cbii-dashboard-last-challenge-card no-link"
    >
      <div className="row">
        <div className="col-md-4 mb-3 d-flex">
          <div className="cbii-dashboard-last-challenge-card-img-container">
            <img
              src={challenge.image}
              alt="challenge"
              className="cbii-dashboard-last-challenge-card-img"
            />
            <button className="cbii-dashboard-last-challenge-card-img-btn">
              <CiHeart style={{ fontSize: 18 }} />
            </button>
          </div>
        </div>
        <div className="col-md-8 mb-3">
          <div className="p-3">
            <div className="cbii-dashboard-last-challenge-card-title-category-container mb-4">
              <div className="cbii-dashboard-last-challenge-card-title">
                {challenge.title}
              </div>
              <div className="cbii-dashboard-last-challenge-card-category-badge-container">
                <div className="cbii-dashboard-last-challenge-card-category-badge">
                  {challenge.category}
                </div>
                <div className="cbii-dashboard-last-challenge-card-category-badge">
                  2+
                </div>
              </div>
            </div>
            <div className="mb-4">
              <p className="cbii-dashboard-last-challenge-card-description">
                {challenge.description}
              </p>
            </div>
            <div className="cbii-dashboard-last-challenge-card-value">
              <img
                src={challenge.icon}
                alt="challenge icon"
                className="cbii-dashboard-last-challenge-card-icon"
              />
              <span>{challenge.amountRaised} FCFA</span>
              levés
            </div>
            <hr className="counter-divider" />
            <ParticipantCounter
              count={challenge.participantCount}
              challengeIcon={challenge.image}
            />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default LastChallengeCard;
