import React, { useState } from "react";
import "./DashboardAdmin.css";
import LineChartPraticien from "./LineChart/LineChartAdmin";
import { useSearch } from "../../../utils/hooks";
import { addDays } from "../../../utils/Utils";
import moment from "moment";

const DashboardAdmin = () => {
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-3">DashboardAdmin</div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
