import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaPlus, FaUserFriends } from "react-icons/fa";
import { BiCategory, BiUser } from "react-icons/bi";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoLogOutOutline } from "react-icons/io5";
import { TbUsers, TbYoga } from "react-icons/tb";
import { useAppDispatch } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import VersionChecker from "../../../../versionChecker/VersionChecker";
import { FaSquarePlus } from "react-icons/fa6";
import { PiMoonStarsFill } from "react-icons/pi";
import { HiOutlineLogout } from "react-icons/hi";
import AddProjectModal from "../../../association/Projets/Modals/AddProjectModal";
import DarkModeToggle from "./DarkModeTogal";

function SidebarAdmin() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(onlogout());
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          {/* <label htmlFor="nav_button" className="on no-view-mobile">
          <FiMenu className="icon-side-menu" />
        </label> */}
          <div className="move-left-side-menu"></div>
          <ul className="nav nav-items-custom flex-column">
            {/* {location?.pathname?.startsWith("/admin") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiCategory />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/medecins"
                    data-tooltip-content="Medecins"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserFriends />
                    </span>
                    <span className="hiddenable-navlink-label">Medecins</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/patientes"
                    data-tooltip-content="Patientes"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserFriends />
                    </span>
                    <span className="hiddenable-navlink-label">Patientes</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/tailles-poids"
                    data-tooltip-content="Tailles"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <GiBodyHeight />
                    </span>
                    <span className="hiddenable-navlink-label">Tailles</span>
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/parametres"
                    data-tooltip-content="Parametres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdOutlineSettingsSuggest />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/messages"
                    data-tooltip-content="Messages"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <CiMail />
                    </span>
                    <span className="hiddenable-navlink-label">Messages</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/forum"
                    data-tooltip-content="Forum"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <FaUserFriends />
                    </span>
                    <span className="hiddenable-navlink-label">Forum</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/messages-contact"
                    data-tooltip-content="Messages de contact"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdOutlineContactMail />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Messages de contact
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom mb-5">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/admin/mon-profil"
                    data-tooltip-content="Mon profil"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiUser />
                    </span>
                    <span className="hiddenable-navlink-label">Mon profil</span>
                  </NavLink>
                </li>
              </>
            )} */}
            {location?.pathname?.startsWith("/association") && (
              <>
                <div className="challenge-sidebar-subtitle my-3">
                  CHALLENGES
                </div>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/association/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiCategory />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/association/challenge-en-cours"
                    data-tooltip-content="En cours"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <TbUsers />
                    </span>
                    <span className="hiddenable-navlink-label">En cours</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/association/challenge-termine"
                    data-tooltip-content="Terminés"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <AiOutlineCalendar />
                    </span>
                    <span className="hiddenable-navlink-label">Terminés</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                    to="/association/rapports"
                    data-tooltip-content="Rapports"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <TbYoga />
                    </span>
                    <span className="hiddenable-navlink-label">Rapports</span>
                  </NavLink>
                </li>
                <hr className="challenge-custom-hr-sidebar m-3" />
                <div className="d-flex align-items-center justify-content-between  position-relative">
                  <NavLink
                    className="challenge-sidebar-subtitle my-3 nav-link nav-link-vertival-custom-admin with-tooltip-menu w-100"
                    to="/association/projets"
                    data-tooltip-content="Projets"
                    style={{ zIndex: "1" }}
                  >
                    Projets
                  </NavLink>
                  <button
                    onClick={() => setModalShow(true)}
                    className="btn custom-btn-add-project"
                    style={{ zIndex: "10" }}
                  >
                    <FaPlus />
                  </button>
                </div>
                <div className="challenge-sidebar-project-container">
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin with-tooltip-menu px-1"
                      to="/association/projets/:slug"
                      data-tooltip-content="Projets"
                    >
                      <div className="challenge-sidebar-project-item">
                        <div className="challenge-sidebar-project-indicator"></div>
                        <div className="challenge-sidebar-project-name">
                          Ecole manguiers <span>(59%)</span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin with-tooltip-menu px-0"
                      to="/association/projets/:slug"
                      data-tooltip-content="Projets"
                    >
                      <div className="challenge-sidebar-project-item">
                        <div className="challenge-sidebar-project-indicator"></div>
                        <div className="challenge-sidebar-project-name">
                          Forage Keur Massar <span>(59%)</span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin with-tooltip-menu px-0"
                      to="/association/projets/:slug"
                      data-tooltip-content="Projets"
                    >
                      <div className="challenge-sidebar-project-item">
                        <div className="challenge-sidebar-project-indicator"></div>
                        <div className="challenge-sidebar-project-name">
                          Puit de l’espoir <span>(59%)</span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom-admin with-tooltip-menu px-0"
                      to="/association/projets/:slug"
                      data-tooltip-content="Projets"
                    >
                      <div className="challenge-sidebar-project-item">
                        <div className="challenge-sidebar-project-indicator"></div>
                        <div className="challenge-sidebar-project-name">
                          Une maison pour tous <span>(59%)</span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <div className="mt-5">
                    <NavLink
                      to="/association/nouveau-challenge"
                      className="btn btn-theme-body-page w-100"
                    >
                      <span>
                        <FaSquarePlus />
                      </span>{" "}
                      Créer un challenge
                    </NavLink>
                  </div>
                </div>

                <AddProjectModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </>
            )}
            <DarkModeToggle />
            <li className="nav-item nav-item-vertical-custom pb-5">
              <button
                className="btn nav-link nav-link-vertival-custom-admin with-tooltip-menu "
                data-tooltip-content="Déconnexion"
                onClick={() => onLogout()}
              >
                <span className="icon-container-nav-link-vertival">
                  <HiOutlineLogout />
                </span>
                <span className="hiddenable-navlink-label">Déconnexion</span>
              </button>
            </li>
            <li className="pb-5">
              <div
                className="version-view-container"
                style={{ padding: "15px 25px" }}
              >
                {"V" + VersionChecker()}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
