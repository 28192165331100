import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./DashboardAssociation.css";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import CustomProgressBar from "../../shared/CustomProgressBar";
import { FaRegCheckSquare } from "react-icons/fa";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { GoDotFill } from "react-icons/go";
import LineChartAssociation from "./LineChart/LineChartAssociation";
import Challeng1 from "../../../assets/appImages/challenge.png";
import Logo from "../../../assets/appImages/logo-org.png";
import ChallengIcon from "../../../assets/appImages/challengeIcon.png";
import LastChallengeCard from "./components/LastChallengeCard";
import ChallengeCard from "./components/ChallengeCard";

function DashboardAssociation() {
  const lastChallengeData = {
    image: Challeng1,
    title: "Ice Bucket challenge",
    category: "Santé",
    description:
      "Le Challenge consiste à se renverser ou se faire renverser un seau d'eau glacée sur la tête pour sensibiliser à la sclérose latérale amyotrophique (SLA) également appelée maladie de Charcot",
    icon: ChallengIcon,
    amountRaised: "500.000",
    participantCount: 250,
  };
  const challengeData = {
    image: Challeng1,
    logo: Logo,
    title: "Ice Bucket challenge",
    category: "Santé",
    description:
      "Le Challenge consiste à se renverser ou se faire renverser un seau d’eau glacée sur la tête pour sensibiliser à la sclérose latérale amyotrophique (SLA) également appelée maladie de Charcot",
    icon: ChallengIcon,
    amountRaised: "500.000",
    participantCount: 250,
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="cbii-dashboard-custom-bg">
              <div className="cbii-dashboard-subtitle mb-3">Projets récents</div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="cbii-dashboard-project-card">
                    <div className="cbii-dashboard-project-title-container mb-3">
                      <div className="cbii-dashboard-project-title">
                        Ecole manguier
                      </div>
                      <Dropdown className="cbii-dashboard-project-btn-action">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="cbii-dashboard-project-btn-action-toggle"
                        >
                          <HiOutlineDotsHorizontal />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="mb-3">
                      <CustomProgressBar value={50} className="completed" />
                    </div>
                    <div className="cbii-dashboard-project-stats">
                      <FaRegCheckSquare />
                      <div>
                        <span>8</span>/15
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="cbii-dashboard-project-card">
                    <div className="cbii-dashboard-project-title-container mb-3">
                      <div className="cbii-dashboard-project-title">
                        Ecole manguier
                      </div>
                      <Dropdown className="cbii-dashboard-project-btn-action">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="cbii-dashboard-project-btn-action-toggle"
                        >
                          <HiOutlineDotsHorizontal />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="mb-3">
                      <CustomProgressBar value={50} className="medium" />
                    </div>
                    <div className="cbii-dashboard-project-stats">
                      <FaRegCheckSquare />
                      <div>
                        <span>8</span>/15
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="cbii-dashboard-project-card">
                    <div className="cbii-dashboard-project-title-container mb-3">
                      <div className="cbii-dashboard-project-title">
                        Ecole manguier
                      </div>
                      <Dropdown className="cbii-dashboard-project-btn-action">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="cbii-dashboard-project-btn-action-toggle"
                        >
                          <HiOutlineDotsHorizontal />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="mb-3">
                      <CustomProgressBar value={50} className="low" />
                    </div>
                    <div className="cbii-dashboard-project-stats">
                      <FaRegCheckSquare />
                      <div>
                        <span>8</span>/15
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 d-flex">
            <div className="cbii-dashboard-custom-bg w-100 d-flex flex-column">
              <div className="cbii-dashboard-subtitle mb-3">
                Collecte pour les projets
              </div>
              <div className="flex-grow-1 d-flex flex-column justify-content-center">
                <div className="w-75 mx-auto">
                  <CircularProgressbarWithChildren value={66}>
                    <div className="cbii-dashboard-progress-circulair-label">
                      Total
                    </div>
                    <div className="cbii-dashboard-progress-circulair-value">
                      <strong>1.227.840</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                  <div className="mt-4 w-75 mx-auto">
                    <div className="cbii-dashboard-progress-circulair-legend">
                      <GoDotFill style={{ fontSize: 20, color: "#6741FF" }} />
                      Collecté: <span>27.840 FCFA</span>
                    </div>
                    <div className="cbii-dashboard-progress-circulair-legend">
                      <GoDotFill style={{ fontSize: 20, color: "#F5F5F5" }} />
                      Restant: <span>22.160 FCFA</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 mb-4 d-flex">
            <div className="cbii-dashboard-custom-bg w-100 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="cbii-dashboard-subtitle">
                  Evolution des dons reçus
                </div>
                <select className="form-control from-control-graph">
                  <option value="patients" selected>
                    Par jour
                  </option>
                  <option value="medecins">Par mois</option>
                  <option value="rdvs">Par année</option>
                </select>
              </div>
              <div className="content-graph-admin flex-grow-1">
                <LineChartAssociation />
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div className="cbii-dashboard-custom-bg">
              <div className="cbii-dashboard-subtitle mb-3">
              Nouvelle sélection dans le cadre d’un challenge
              </div>
              <LastChallengeCard challenge={lastChallengeData} />
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div className="cbii-dashboard-custom-bg">
              <div className="cbii-dashboard-subtitle mb-3">
                Challenge en cours
              </div>
              <div className="row">
                {[...Array(6)].map((_, index) => (
                  <div className="col-md-4 mb-3" key={index}>
                    <ChallengeCard challenge={challengeData} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAssociation;
