import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const ImageWithOverlayText = ({ imageSrc, overlayText }) => {
  return (
    <div className="image-container">
      <PhotoProvider>
        <PhotoView src={imageSrc}>
          <img
            src={imageSrc}
            alt="post"
            className="cbii-details-challenge-card-participation-item-img-post"
          />
        </PhotoView>
      </PhotoProvider>
      <div className="overlay-text">
        <p>{overlayText}</p>
      </div>
    </div>
  );
};

export default ImageWithOverlayText;
