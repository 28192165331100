import React from 'react';
import { BiFilm } from 'react-icons/bi';
import { IoFilmOutline } from 'react-icons/io5';
import { TbPhotoEdit } from 'react-icons/tb';

const MediaSelector = ({ selected, onSelect }) => {
    return (
        <div className="radio-button-selector">
          <label className={`radio-button ${selected === 'photos' ? 'selected' : ''}`}>
            <input
              type="radio"
              name="mediaType"
              value="photos"
              checked={selected === 'photos'}
              onChange={() => onSelect('photos')}
            />
            <span className="radio-content">
              <span className="icon photos-icon">
              <TbPhotoEdit />
              </span>
              <span className="label">Photos</span>
              {selected === 'photos' && <span className="checkmark"></span>}
            </span>
          </label>
          <label className={`radio-button ${selected === 'videos' ? 'selected' : ''}`}>
            <input
              type="radio"
              name="mediaType"
              value="videos"
              checked={selected === 'videos'}
              onChange={() => onSelect('videos')}
            />
            <span className="radio-content">
              <span className="icon videos-icon">
              <IoFilmOutline />
              </span>
              <span className="label">Vidéos</span>
              {selected === 'videos' && <span className="checkmark"></span>}
            </span>
          </label>
        </div>
      );
};

export default MediaSelector;