import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ColLeftLogin } from "./LoginAssos";
const VerifierCode = () => {
  return (
    <div className="auth-component">
      <div className="fixed-after-header fixed-login-page-after-header">
        <div className="fixed-login-page">
          <div className="auth-container">
            <div className="auth-row row">
              <div className="col-md-6 auth-col auth-left-side-col">
                <ColLeftLogin />
              </div>
              <div className="col-md-6 auth-col auth-right-side-col">
                <div className="auth-right-side-container">
                  <div className="p-5">
                    <div className="auth-form-container">
                      <h1 className="auth-form-title">Code de vérification</h1>
                      <div className="content-text-redirect-auth mt-4">
                        <span className="info-connect-redirection">
                          Veuillez saisir le code que vous avez reçu par e-mail
                          ou numéro de téléphone
                        </span>
                      </div>
                      <div className="mt-4 content-auth-form">
                        <form id="auth-form">
                          <div className="row auth-form-row">
                            <div className="container-vewrify-code mb-3">
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`number`}
                                  className="form-control auth-form-control-code"
                                  id="number"
                                  aria-label="code"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`number`}
                                  className="form-control auth-form-control-code"
                                  id="number"
                                  aria-label="code"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`number`}
                                  className="form-control auth-form-control-code"
                                  id="number"
                                  aria-label="code"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                              <div className="form-group auth-form-group auth-form-group-login">
                                <input
                                  type={`number`}
                                  className="form-control auth-form-control-code"
                                  id="number"
                                  aria-label="code"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </div>
                            <div className="content-text-redirect-auth my-4">
                              <span className="info-connect-redirection">
                                01:59
                              </span>
                              <NavLink to="/" className="redirect-text-auth">
                                Renvoyer le code de vérification
                              </NavLink>
                            </div>
                            <div className="col-md-12 auth-submit-btn-container">
                              <NavLink
                                to="/justificatifs"
                                className="btn auth-submit-btn-login trans-0-2"
                              >
                                S’inscrire
                              </NavLink>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifierCode;
