import React, { useState } from 'react';
import Wave from "../../../assets/appImages/wave.png"
import Om from "../../../assets/appImages/om.png"
import Cart from "../../../assets/appImages/card.png"

const Datas = [
  {
    id: 1,
    libelle: "Wave",
    image: Wave
  },
  {
    id: 2,
    libelle: "Orange Money",
    image: Om
  },
  {
    id: 3,
    libelle: "Carte bancaire",
    image: Cart,
    subtitle: "1982 xxx xxx xxxx xxx"
  }
];

const PaymentOptionSelector = () => {
  const [selectedOption, setSelectedOption] = useState<any>(null);

  return (
    <div className="payment-options">
      {Datas.map((option) => (
        <label
          key={option.id}
          className={`payment-option ${selectedOption === option.id ? 'selected' : ''}`}
        >
          <input
            type="radio"
            name="paymentOption"
            value={option.id}
            checked={selectedOption === option.id}
            onChange={() => setSelectedOption(option.id)}
          />
          <div className="option-content">
            <div className="option-icon">
              <img src={option.image} alt="moyen de paiement" className="payment-option-image" />
            </div>
            <div className="option-text">
              <div className="option-label">{option.libelle}</div>
              {option.subtitle && <div className="option-subtitle">{option.subtitle}</div>}
            </div>
          </div>
          <div className="check-indicator"></div>
        </label>
      ))}
    </div>
  );
};

export default PaymentOptionSelector;