import React from "react";
import Modal from "react-bootstrap/Modal";
import { Input } from "../../../common/Input";
import { SelectInput } from "../../../common/SelectInput";

function AddProjectModal(props) {
  const selectCategory = [
    { label: "Catégorie 1", value: 1 },
    { label: "Catégorie 2", value: 2 },
    { label: "Catégorie 3", value: 3 },
  ];
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="cbii-modal-add-project-header px-4">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="cbii-modal-add-project-title"
        >
          Nouveau projet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form action="">
          <div className="row">
            <div className="col-md-7 mb-3">
              <Input
                type="text"
                id="titre"
                placeholder="Titre"
                aria-label="titre"
                label="Titre"
                customClassLabel={"form-label-modal-add"}
                customClassInput="input-modal-form-add h-50"
              />
            </div>
            <div className="col-md-5 mb-3">
              <label className="form-label form-label-modal-add">
              Catégorie
              </label>
              <SelectInput
                hiddenFirstOption
                options={selectCategory}
                customClassInput={
                  "auth-form-control-add h-50 custom-select-padding"
                }
              />
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label form-label-modal-add">
                Description
              </label>
              <textarea
                className="form-control input-modal-form-add-textarea custom-border-radius-8"
                id="description"
                placeholder="Description"
                rows={4}
              ></textarea>
            </div>
            <div className="col-md-6 mb-3">
              <Input
                type="text"
                id="montant"
                placeholder="Montant souhaité"
                aria-label="montant"
                label="Montant souhaité"
                customClassLabel={"form-label-modal-add"}
                customClassInput="input-modal-form-add h-50"
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                type="text"
                id="responsable"
                placeholder="Responsable de projet"
                aria-label="responsable"
                label="Responsable de projet"
                customClassLabel={"form-label-modal-add"}
                customClassInput="input-modal-form-add h-50"
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                type="date"
                id="date_debut"
                placeholder="Date de début"
                aria-label="date_debut"
                label="Date de début"
                customClassLabel={"form-label-modal-add"}
                customClassInput="input-modal-form-add h-50"
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                type="date"
                id="date_fin"
                placeholder="Date de fin"
                aria-label="date_fin"
                label="Date de fin"
                customClassLabel={"form-label-modal-add"}
                customClassInput="input-modal-form-add h-50"
              />
            </div>
            <div className="col-md-12 mt-4">
              <div className="cbii-modal-add-project-btn-container">
                <button className="prev-button w-100">Annuler</button>
                <button className="next-button w-100">Ajouter</button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddProjectModal;
