import React from "react";
import { useStep } from "react-hooks-helper";
import "./ProjetsAssociation.css";
import ProjetsEncoursTab from "./ProjetsTabs/ProjetsEncoursTab";
import ProjetsTerminesTab from "./ProjetsTabs/ProjetsTerminesTab";
import AddProjectModal from "./Modals/AddProjectModal";
import { FaSquarePlus } from "react-icons/fa6";
import { useStepType } from "../../../utils/type";

const steps = [
  { id: "en-cours", Component: ProjetsEncoursTab },
  {
    id: "termines",
    Component: ProjetsTerminesTab,
  },
];
const tabs = ["En cours", "Terminés"];

function ProjetsAssociation() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="mb-4 cbii-tab-container">
        <ul className="tabs-container parametre-tabs-container-admin mb-0 pt-4 pb-2">
          {tabs.map((tab: any, key: any) => (
            <li
              className={`param-tab-item  ${
                index === key && "active-param-tab"
              }`}
              key={key}
              onClick={() => go(key)}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          <Component />
          <div className="col-md-12 mt-4 d-flex justify-content-end">
        <button className="btn btn-theme-body-page" onClick={() => setModalShow(true)}>
          <span>
            <FaSquarePlus />
          </span>{" "}
          Nouveau projet
        </button>
        <AddProjectModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
      </div>
        </div>
      </div>
    </>
  );
}

export default ProjetsAssociation;
