import React from 'react'
import "./ChallengeTerminer.css"
import ChallengeCard from '../DashboardAssociation/components/ChallengeCard'
import Challeng1 from "../../../assets/appImages/challenge.png";
import Logo from "../../../assets/appImages/logo-org.png";
import ChallengIcon from "../../../assets/appImages/challengeIcon.png";
import TotalCollecteIcon from "../../../assets/icons/totalCollecte.png";
import TotalParticipationIcon from "../../../assets/icons/totalParticipation.png";
import MoyenneCollecteIcon from "../../../assets/icons/moyenneCollecte.png";
import TauxContributionIcon from "../../../assets/icons/tauxContribution.png";

function ChallengeTerminer() {
    const challengeData = {
        image: Challeng1,
        logo: Logo,
        title: "Ice Bucket challenge",
        category: "Santé",
        description:
          "Le Challenge consiste à se renverser ou se faire renverser un seau d’eau glacée sur la tête pour sensibiliser à la sclérose latérale amyotrophique (SLA) également appelée maladie de Charcot",
        icon: ChallengIcon,
        amountRaised: "500.000",
        participantCount: 250,
      };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="container-bg-dashboard-challenge-en-cours">
              <div className="row row-challenge-en-cours">
                <div className="col-lg-3 col-md-4 mb-3">
                    <div className="cbii-challenge-terminer-card-statistique cbii-dashboard-custom-bg">
                        <div className='cbii-challenge-terminer-card-statistique-content'>
                            <div className='cbii-challenge-terminer-card-statistique-content-img-container'>
                                <img src={TotalCollecteIcon} alt="icon" className='cbii-challenge-terminer-card-statistique-content-img' />
                            </div>
                            <div className='cbii-challenge-terminer-card-statistique-content-label-container'>
                                <div className='cbii-challenge-terminer-card-statistique-content-label'>Total collecté</div>
                                <div className='cbii-challenge-terminer-card-statistique-content-value'>3,5M <span className='up'>+12%</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 mb-3">
                    <div className="cbii-challenge-terminer-card-statistique cbii-dashboard-custom-bg">
                        <div className='cbii-challenge-terminer-card-statistique-content'>
                            <div className='cbii-challenge-terminer-card-statistique-content-img-container'>
                                <img src={TotalParticipationIcon} alt="icon" className='cbii-challenge-terminer-card-statistique-content-img' />
                            </div>
                            <div className='cbii-challenge-terminer-card-statistique-content-label-container'>
                                <div className='cbii-challenge-terminer-card-statistique-content-label'>Total de participants</div>
                                <div className='cbii-challenge-terminer-card-statistique-content-value'>5K <span className='up'>+12%</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 mb-3">
                    <div className="cbii-challenge-terminer-card-statistique cbii-dashboard-custom-bg">
                        <div className='cbii-challenge-terminer-card-statistique-content'>
                            <div className='cbii-challenge-terminer-card-statistique-content-img-container'>
                                <img src={MoyenneCollecteIcon} alt="icon" className='cbii-challenge-terminer-card-statistique-content-img' />
                            </div>
                            <div className='cbii-challenge-terminer-card-statistique-content-label-container'>
                                <div className='cbii-challenge-terminer-card-statistique-content-label'>Moyenne de collecte</div>
                                <div className='cbii-challenge-terminer-card-statistique-content-value'>1,M <span className='down'>-5%</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 mb-3">
                    <div className="cbii-challenge-terminer-card-statistique cbii-dashboard-custom-bg">
                        <div className='cbii-challenge-terminer-card-statistique-content'>
                            <div className='cbii-challenge-terminer-card-statistique-content-img-container'>
                                <img src={TauxContributionIcon} alt="icon" className='cbii-challenge-terminer-card-statistique-content-img' />
                            </div>
                            <div className='cbii-challenge-terminer-card-statistique-content-label-container'>
                                <div className='cbii-challenge-terminer-card-statistique-content-label'>Taux de contribution</div>
                                <div className='cbii-challenge-terminer-card-statistique-content-value'>5,6% <span className='up'>+15%</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-4">
                    <div className="cbii-dashboard-custom-bg">
                        <div className="cbii-dashboard-subtitle mb-3">
                            Terminés
                        </div>
                        <div className="row">
                            {[...Array(6)].map((_, index) => (
                            <div className="col-lg-4 col-md-6 mb-3" key={index}>
                                <ChallengeCard challenge={challengeData} />
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChallengeTerminer