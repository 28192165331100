import React, { useState } from "react";
import "./NouveauChallenge.css";
import Infoschallenge from "./steps/InfosChallenge";
import SecteurChallenge from "./steps/SecteurChallenge";
import UploadMedia from "./steps/UploadMedia";
import MontantParticipation from "./steps/MontantParticipation";
import { FaCheckCircle } from "react-icons/fa";
import { IoRadioButtonOn } from "react-icons/io5";

const steps = [
  {
    id: 1,
    title: "Étape 1",
    subtitle: "Informations sur le challenge",
    Component: Infoschallenge,
  },
  {
    id: 2,
    title: "Étape 2",
    subtitle: "Secteurs liés au challenge",
    Component: SecteurChallenge,
  },
  {
    id: 3,
    title: "Étape 3",
    subtitle: "Upload de média",
    Component: UploadMedia,
  },
  {
    id: 4,
    title: "Étape 4",
    subtitle: "Montant de participation",
    Component: MontantParticipation,
  },
];

function Component({
  currentStep,
  ...props
}: {
  currentStep: number;
  [key: string]: any;
}) {
  const StepComponent = steps[currentStep - 1].Component;
  return <StepComponent {...props} />;
}

const Stepper = ({ currentStep }) => (
  <div className="stepper">
    {steps.map((step, index) => (
      <React.Fragment key={step.id}>
        <div
          className={`step ${step.id === currentStep ? "active" : ""} ${
            step.id < currentStep ? "completed" : ""
          }`}
        >
          <div className="step-icon">
            {step.id < currentStep ? (
              <FaCheckCircle className="check-icon" />
            ) : step.id === currentStep ? (
              <IoRadioButtonOn className="current-icon" />
            ) : (
              <IoRadioButtonOn className="future-icon" />
            )}
          </div>
          <div className="step-content">
            <div className="step-title">{step.title}</div>
            <div className="step-subtitle">{step.subtitle}</div>
          </div>
        </div>
        {index < steps.length - 1 && <div className="step-line" />}
      </React.Fragment>
    ))}
  </div>
);

const NouveauChallenge = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="multi-step-form cbii-dashboard-custom-bg custom-padding-50">
              <Stepper currentStep={currentStep} />

              <form>
                <Component currentStep={currentStep} />
              </form>
              <div className="form-navigation mt-5">
                <button
                  onClick={prevStep}
                  disabled={currentStep === 1}
                  className="prev-button"
                >
                  Précédent
                </button>
                <button
                  onClick={nextStep}
                  disabled={currentStep === steps.length}
                  className="next-button"
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NouveauChallenge;
