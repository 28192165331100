import React from "react";
import ProfileIcon from "../../../../assets/icons/svg/profile.svg";
import CalendarIcon from "../../../../assets/icons/svg/calendar.svg";
import TicketIcon from "../../../../assets/icons/svg/ticket.svg";
import GalleryIcon from "../../../../assets/icons/svg/gallery.svg";
import TagIcon from "../../../../assets/icons/svg/tag.svg";

function DetailsTab() {
  return (
    <div className="cbii-details-challenge-tab-details">
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="cbii-details-challenge-tab-details-card-infos-item">
            <div className="mb-1">
              <img
                src={ProfileIcon}
                alt="profile icon"
                className="cbii-details-challenge-tab-details-card-infos-item-img"
              />
            </div>
            <div className="cbii-details-challenge-tab-details-card-infos-item-label">
              Dudu fait des vidéos
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="cbii-details-challenge-tab-details-card-infos-item">
            <div className="mb-1">
              <img
                src={CalendarIcon}
                alt="calendar icon"
                className="cbii-details-challenge-tab-details-card-infos-item-img"
              />
            </div>
            <div className="cbii-details-challenge-tab-details-card-infos-item-label">
              Du 3 Janvier 2023 - Au 17 Février 2023
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="cbii-details-challenge-tab-details-card-infos-item">
            <div className="mb-1">
              <img
                src={TicketIcon}
                alt="ticket icon"
                className="cbii-details-challenge-tab-details-card-infos-item-img"
              />
            </div>
            <div className="cbii-details-challenge-tab-details-card-infos-item-label">
              Ticket minimum pour participer <br />
              <span>1.500 FCFA</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="cbii-details-challenge-tab-details-card-infos-item flex-row justify-content-start">
            <div className="mb-1">
              <img
                src={GalleryIcon}
                alt="gallery icon"
                className="cbii-details-challenge-tab-details-card-infos-item-img"
              />
            </div>
            <div className="cbii-details-challenge-tab-details-card-infos-item-label">
              Photos & Vidéos
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="cbii-details-challenge-tab-details-description-title mb-3">
            Description
          </div>
          <div className="cbii-details-challenge-tab-details-description">
            <p>
              Le Challenge consiste à se renverser ou se faire renverser un seau
              d’eau glacée sur la tête pour sensibiliser à la sclérose latérale
              amyotrophique (SLA) également appelée maladie de Charcot. Prêt(e)s
              pour un défi rafraîchissant ? Joignez-vous à moi pour le Ice
              Bucket Challenge ! On verse un seau d'eau glacée sur nous, on
              filme et on nomine 3 potes pour faire de même !
            </p>
          </div>
          <div className="cbii-details-challenge-tab-details-tag-container mt-5">
            <span>
              <img
                src={TagIcon}
                alt="tag icon"
                className="cbii-details-challenge-tab-details-card-infos-item-img"
              />
            </span>
            <div className="cbii-details-challenge-tab-details-badge-container">
              <div className="cbii-dashboard-last-challenge-card-category-badge">
                Santé
              </div>
              <div className="cbii-dashboard-last-challenge-card-category-badge">
                Santé
              </div>
              <div className="cbii-dashboard-last-challenge-card-category-badge">
                Santé
              </div>
              <div className="cbii-dashboard-last-challenge-card-category-badge">
                Santé
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsTab;
