import React from "react";
import ListDocumentsTable from "../Projets/Tables/ListDocumentsTable";
import AddNewFileModale from "./Modals/AddNewFileModale";

function RapportAssociation() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="cbii-details-projet-tab-documents">
        <div className="cbii-details-projet-tab-documents-header">
          <div className="cbii-details-projet-tab-media-title-container mb-3">
            <div className="cbii-details-projet-tab-media-title">
              Listes des rapports
            </div>
            <div className="cbii-details-projet-tab-media-subtitle">
              35 éléments
            </div>
          </div>
          <button
            className="btn btn-theme-body-page"
            onClick={() => setModalShow(true)}
          >
            Ajouter un fichier
          </button>
        </div>
        <div className="association-table">
          <ListDocumentsTable />
        </div>
      </div>
      <AddNewFileModale show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default RapportAssociation;
