import React from "react";
import Modal from "react-bootstrap/Modal";
import avatar from "../../../../assets/appImages/Oval.png";
import PostImg from "../../../../assets/appImages/post.png";
import { PiDotsThreeOutline } from "react-icons/pi";
import { FiSend } from "react-icons/fi";
import { Input } from "../../../common/Input";

function DetailsChallengeModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="cbii-details-challenge-card-modal-body">
        <div className="row">
          <div className="col-lg-6 pe-lg-0">
            <img
              src={PostImg}
              alt="post"
              className="cbii-details-challenge-card-participation-item-img-post"
            />
          </div>
          <div className="col-lg-6 ps-lg-0">
            <div className="cbii-details-challenge-card-modal-col-right position-relative">
              <div className="cbii-details-challenge-card-participation-item-header">
                <div className="cbii-details-challenge-card-participation-item-user-infos-container">
                  <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
                    <img
                      src={avatar}
                      alt="user avatar"
                      className="cbii-details-challenge-card-participation-item-user-avatar"
                    />
                  </div>
                  <div className="cbii-details-challenge-card-participation-item-user-name-container">
                    <div className="cbii-details-challenge-card-participation-item-user-name">
                      That Boy Dudu
                    </div>
                    <div className="cbii-details-challenge-card-participation-item-user-pseudo">
                      @dudufaitdesvideos
                    </div>
                  </div>
                </div>
                <button className="btn cbii-details-challenge-card-participation-item-btn-more">
                  <PiDotsThreeOutline style={{ fontSize: "22px" }} />
                </button>
              </div>
              <div className="cbii-details-challenge-card-modal-para custom-border">
                <p>
                  Woo-hoo ! Je viens de relever le défi du Ice Bucket Challenge
                  et c'était génial ! C'était à la fois rafraîchissant et
                  amusant, et surtout, je sais que nous contribuons à une cause
                  importante. Je nomine maintenant @Mahfousse, @MameBalla, et
                  @saneekh à relever le défi à leur tour ! Vous avez 24 heures
                  pour le faire les gars, montrez-nous ce que vous avez dans le
                  ventre ! 😄
                </p>
              </div>
              <div className="cbii-details-challenge-card-modal-commentaire-item-container">
                {[...Array(6)].map((_, index) => (
                  <div className="mb-3" key={index}>
                    <CommentaireItem />
                  </div>
                ))}
              </div>
              <div className="cbii-details-challenge-card-modal-footer">
                <div className="cbii-details-challenge-card-participation-item-footer-input-container">
                  <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
                    <img
                      src={avatar}
                      alt="user avatar"
                      className="cbii-details-challenge-card-participation-item-user-avatar"
                    />
                  </div>
                  <Input
                    type="text"
                    id="commentaire"
                    placeholder="Insérez votre commentaire..."
                    aria-label="commentaire"
                    customClassContainer={"w-100 mb-0"}
                    customClassInput="input-modal-form-add-commentaire"
                  />
                  <button className="btn btn-send-comment">
                    <FiSend style={{ fontSize: "35px" }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DetailsChallengeModal;

export function CommentaireItem() {
  return (
    <div className="cbii-details-challenge-card-modal-commentaire-item">
      <div className="cbii-details-challenge-card-participation-item-user-avatar-container">
        <img
          src={avatar}
          alt="user avatar"
          className="cbii-details-challenge-card-participation-item-user-avatar"
        />
      </div>
      <div className="cbii-details-challenge-card-modal-commentaire-item-content-container w-100">
        <div className="cbii-details-challenge-card-modal-commentaire-item-content">
          <div className="cbii-details-challenge-card-participation-item-user-name mb-4">
            Bamba Ndiaye
          </div>
          <div className="cbii-details-challenge-card-modal-commentaire-item-para mb-3">
            <p>
              MDR !!! Mec tu tremblais commef un fou MDR !!!
              <br />
              Mec tu trefsdfff😂
            </p>
          </div>
          <div className="cbii-details-challenge-card-participation-item-footer-stats-reactions custom">
            <div className="cbii-details-challenge-card-participation-item-footer-stats-reactions-icon-container">
              <span>😩</span>
              <span>😍</span>
            </div>
            13
          </div>
        </div>
        <div className="cbii-details-challenge-card-modal-commentaire-item-btn-action-container">
          <button className="btn cbii-details-challenge-card-modal-commentaire-item-btn-action">
            Réagir
          </button>
          <button className="btn cbii-details-challenge-card-modal-commentaire-item-btn-action">
            Répondre
          </button>
        </div>
      </div>
    </div>
  );
}
