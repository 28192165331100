import React, { Dispatch, SetStateAction } from "react";
import DatePicker from "react-datepicker";
import Chart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";

function LineChartAssociation() {

  const series = [
    {
      name: "Montant",
      data: [30000, 25000, 40000, 35000, 37000, 45000, 43000, 47000]
    },
  ];

  const options: any = {
    chart: {
      height: 280,
      type: "area",
      zoom: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#6741FF"],
    fill: {
      type: "solid",
      colors: ["rgba(37, 99, 235, 0.08)"],
    },
    xaxis: {
      categories: [
        "Dec 24",
        "Dec 25",
        "Dec 26",
        "Dec 27",
        "Dec 28",
        "Dec 29",
        "Dec 30",
        "Dec 31",
      ],
      labels: {
        rotate: 15,
        style: {
          colors: "#A6A6A6",
          cssClass: "string",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "19.2px",
            textAlign: "left",

        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#A6A6A6",
          cssClass: "string",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "19.2px",
            textAlign: "left",
        },
      },
    },
  };

//   const onChange = (start: any) => {
//     setDate(start);
//   };



  return (
    <div className="chart-item-container">
      {/* {!!isLoading && <Skeleton width={`100%`} height={350} />} */}
      
        <Chart
          className="chart-dashboard-marketplace"
          options={options}
          series={series || []}
          data="Dons"
          type="area"
          width="100%"
          height="350"
        />
      
    </div>
  );
}

export default LineChartAssociation;
