import React from 'react'
import { Input } from '../../../common/Input'
import PaymentOptionSelector from "../PaymentOptionSelector"

function MontantParticipation() {
  return (
    <div className="cbii-association-new-challenge-step-container custom-w-50 mx-auto pt-4 row">
      <div className="col-md-12 auth-input-col">
        <Input
          type="text"
          label="Renseignez un montant minimum pour challenger"
          id="montant"
          placeholder="Montant minimum pour challenger"
          aria-label="montant"
          customClassLabel={"form-label-cbii-custom"}
          customClassInput="input-modal-form-add"
        />
      </div>
      <div className="col-md-12 auth-input-col mb-3">
        <label className={`form-label form-label-cbii-custom `}>
          Choisissez un moyen de paiement
        </label>
        <div className="content-col-add-secteur-radio">
        <PaymentOptionSelector />
          </div>
      </div>
    </div>
  )
}

export default MontantParticipation