import React from "react";
import ListDocumentsTable from "../Tables/ListDocumentsTable";

function DocumentsTab() {
  return (
    <div className="cbii-details-projet-tab-documents">
      <div className="cbii-details-projet-tab-documents-header">
        <div className="cbii-details-projet-tab-media-title-container mb-3">
          <div className="cbii-details-projet-tab-media-title">Listes des factures</div>
          <div className="cbii-details-projet-tab-media-subtitle">
            35 éléments
          </div>
        </div>
        <button className="btn btn-theme-body-page">Ajouter un fichier</button>
      </div>
      <div className="association-table">
        <ListDocumentsTable />
      </div>
    </div>
  );
}

export default DocumentsTab;
