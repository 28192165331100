const ParticipantCounter = ({ count, challengeIcon }) => {
    const avatarCount = Math.min(count, 5);
  
    return (
      <div className="participant-counter my-3">
        <div className="participant-avatars">
          {[...Array(avatarCount)].map((_, index) => (
            <div key={index} className="avatar">
              <div className="avatar-placeholder">
              <img src={challengeIcon} alt="Challenge icon" className="challenge-icon" />
              </div>
            </div>
          ))}
        </div>
        <span className="participant-count">{count} participants</span>
      </div>
    );
  };
  
  export default ParticipantCounter;